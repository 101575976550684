var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "section",
        {
          staticClass: "login-area black-bg p-0 py-4 py-sm-2",
        },
        [
          _c(
            "div",
            {
              staticClass: "row content",
            },
            [
              _c(
                "div",
                {
                  staticClass: "login-section col-lg-6 col-md-12 px-0 py-3",
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass: "sr-only",
                    },
                    [_vm._v(_vm._s(_vm.fcoM["common.logIn"]))]
                  ),
                  _vm._v(" "),
                  _c("ValidationObserver", {
                    attrs: {
                      tag: "div",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (_ref) {
                          var validate = _ref.validate
                          return [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    validate().then(_vm.handleSubmit)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "login-form",
                                  },
                                  [
                                    _vm.lockWarning
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "alert alert-danger p-2 d-flex",
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c("Icon", {
                                                    staticClass:
                                                      "medium red lock",
                                                    attrs: {
                                                      name: "lock",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "flex-fill",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.fcoM[
                                                          "rs.home.lockWarning"
                                                        ]
                                                      ) +
                                                      "\n                                            "
                                                  ),
                                                  _c(
                                                    "FcoLink",
                                                    {
                                                      staticClass:
                                                        "gtm-login-forgot-password",
                                                      attrs: {
                                                        to: "/forgot-password",
                                                        href: _vm.fcoUrl(
                                                          "/forgotpassword.html"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.fcoM[
                                                            "rs.home.forgotUserPass"
                                                          ]
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.loginErrorMessage
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "alert alert-danger p-2 d-flex",
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c("Icon", {
                                                  staticClass:
                                                    "medium red exclamation",
                                                  attrs: {
                                                    name: "exclamation",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "flex-fill",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.loginErrorMessage
                                                    ) +
                                                    "\n                                        "
                                                ),
                                                _vm.showForgotPassword
                                                  ? _c(
                                                      "FcoLink",
                                                      {
                                                        staticClass:
                                                          "gtm-login-forgot-password",
                                                        attrs: {
                                                          to: "/forgot-password",
                                                          href: _vm.fcoUrl(
                                                            "/forgotpassword.html"
                                                          ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.fcoM[
                                                              "rs.home.forgotUserPass"
                                                            ]
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "user-name select-box mb-2",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "sr-only",
                                            attrs: {
                                              for: "userName",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.fcoM["common.userName"]
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("ValidationProvider", {
                                          attrs: {
                                            rules: {
                                              required: true,
                                              basicChars: true,
                                              regex:
                                                /^[A-Za-z0-9]{3,}(?:@oreillyauto\.(com|mx))?$/,
                                              min: 3,
                                              max: 50,
                                            },
                                            name: _vm.fcoM["common.userName"],
                                            tag: "div",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (_ref2) {
                                                  var errors = _ref2.errors,
                                                    classes = _ref2.classes
                                                  return [
                                                    _c("Icon", {
                                                      staticClass:
                                                        "input-icon medium med-gray user",
                                                      attrs: {
                                                        name: "user",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.loginForm
                                                              .loginName,
                                                          expression:
                                                            "loginForm.loginName",
                                                        },
                                                      ],
                                                      class: [
                                                        classes,
                                                        "qaa-js-login-user",
                                                        "highlight-on-focus",
                                                      ],
                                                      attrs: {
                                                        type: "text",
                                                        id: "loginName",
                                                        placeholder:
                                                          _vm.fcoM[
                                                            "common.userName"
                                                          ],
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.loginForm
                                                            .loginName,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.loginForm,
                                                            "loginName",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    errors[0]
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "error",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "password select-box mb-2",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "sr-only",
                                            attrs: {
                                              for: "password",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.fcoM["common.password"]
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("ValidationProvider", {
                                          attrs: {
                                            rules: {
                                              required: true,
                                              min: 3,
                                            },
                                            name: _vm.fcoM["common.password"],
                                            tag: "div",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (_ref3) {
                                                  var errors = _ref3.errors,
                                                    classes = _ref3.classes
                                                  return [
                                                    _c("Icon", {
                                                      staticClass:
                                                        "input-icon medium med-gray lock",
                                                      attrs: {
                                                        name: "lock",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.loginForm
                                                              .password,
                                                          expression:
                                                            "loginForm.password",
                                                        },
                                                      ],
                                                      class: [
                                                        classes,
                                                        "qaa-js-login-password",
                                                        "highlight-on-focus",
                                                      ],
                                                      attrs: {
                                                        type: "password",
                                                        id: "password",
                                                        placeholder:
                                                          _vm.fcoM[
                                                            "common.password"
                                                          ],
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.loginForm
                                                            .password,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.loginForm,
                                                            "password",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    errors[0]
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "error",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "remember-me d-block d-sm-flex",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "custom-control custom-checkbox",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.loginForm.rememberMe,
                                                  expression:
                                                    "loginForm.rememberMe",
                                                },
                                              ],
                                              staticClass:
                                                "ignore custom-control-input",
                                              attrs: {
                                                id: "rememberMe",
                                                type: "checkbox",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.loginForm.rememberMe
                                                )
                                                  ? _vm._i(
                                                      _vm.loginForm.rememberMe,
                                                      null
                                                    ) > -1
                                                  : _vm.loginForm.rememberMe,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.loginForm.rememberMe,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.loginForm,
                                                          "rememberMe",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.loginForm,
                                                          "rememberMe",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.loginForm,
                                                      "rememberMe",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "d-inline-block white custom-control-label",
                                                attrs: {
                                                  for: "rememberMe",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.fcoM[
                                                        "rs.home.staySignedIn"
                                                      ]
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "FcoLink",
                                          {
                                            staticClass:
                                              "ml-auto gtm-login-forgot-password forgotUsernamePasswordLink",
                                            attrs: {
                                              to: "/forgot-password",
                                              href: _vm.fcoUrl(
                                                "/forgotpassword.html"
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.fcoM[
                                                    "rs.home.forgotUserPass"
                                                  ]
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "submit d-block pt-3",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "button py-2 px-3 mr-0 mr-sm-2 mr-lg-1 gtm-login qaa-gtm-login",
                                            attrs: {
                                              type: "submit",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.fcoM["common.logIn"]
                                                ) +
                                                "\n                                        "
                                            ),
                                            !_vm.isLoading
                                              ? _c("Icon", {
                                                  staticClass:
                                                    "medium chevron-right",
                                                  attrs: {
                                                    id: "gtm-svg-login",
                                                    name: "chevron-right",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.isLoading
                                              ? _c("Icon", {
                                                  staticClass: "medium refresh",
                                                  attrs: {
                                                    name: "refresh",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "chat-help",
                                          attrs: {
                                            id: "chatHelpHomePageLink",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-lg-6 col-md-12 login-section",
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass: "sr-only",
                    },
                    [_vm._v(_vm._s(_vm.fcoM["common.requestAccess"]))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "request-access mt-lg-3 p-4 p-md-0 d-md-flex d-lg-block",
                    },
                    [
                      _c(
                        "FcoLink",
                        {
                          staticClass:
                            "button py-2 px-3 mr-0 mr-sm-2 mr-sm-0 text-center qaa-request-access d-block d-lg-inline-block",
                          attrs: {
                            to: "/account-request",
                            href: _vm.fcoUrl("/accountrequest/request.html"),
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.fcoM["common.requestAccess"]) +
                              "\n                        "
                          ),
                          _c("Icon", {
                            staticClass: "medium chevron-right",
                            attrs: {
                              name: "chevron-right",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "mb-0 ml-0 ml-sm-2 ml-lg-0",
                        domProps: {
                          innerHTML: _vm._s(_vm.fcoM["rs.home.requestAssist"]),
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.$fcoMq.isSmDown
        ? _c(
            "section",
            {
              staticClass: "marketing-area white-bg d-none d-sm-block pb-4",
            },
            [
              _c(
                "div",
                {
                  staticClass: "content mb-0 d-none d-md-block",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "col-9",
                        },
                        [
                          _c(
                            "h2",
                            {
                              staticClass: "sr-only",
                            },
                            [_vm._v(_vm._s(_vm.fcoM["rs.home.carouselHeader"]))]
                          ),
                          _vm._v(" "),
                          _c("BannerZone", {
                            attrs: {
                              name: "login-slider",
                              carousel: "",
                              placeholder: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 d-flex flex-wrap",
                        },
                        [
                          _c("BannerZone", {
                            staticClass: "gtm-login-credit",
                            attrs: {
                              name: "login-side-banner-top",
                              placeholder: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("BannerZone", {
                            staticClass: "gtm-login-direct-capital",
                            attrs: {
                              name: "login-side-banner-bottom",
                              placeholder: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("MarketingBoxes"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }