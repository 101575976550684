// A mixin providing shared functionality and computed data for each of the vehicle selector components
import analytics, { GTM } from 'fcoModules/analytics';
import { mapState } from 'vuex';
import { isRequestIdle, isRequestPending } from '../store/request-status';

export default {
    emits: ['vehicle-selected'],
    props: {
        promptAboutQuote: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            GTM,
            userWantsExistingQuote: false,
        };
    },
    computed: {
        ...mapState({
            page: (state) => state.page,
            productsInCurrentQuote: (state) => state.miniQuote.quoteDetails.totalItems > 0,
            isSPA: ({ isSPA }) => isSPA,
        }),
        ...mapState('vehicleSelector', {
            vsData: (state) => state.vsData,
            forceUrl: (state) => state.forceUrl,
            isOpenDefault: (state) => state.isOpenDefault,
            partTypePendingForceCar: (state) => state.partTypePendingForceCar,
            saveLookupBeforeRedirect: (state) => state.saveLookupBeforeRedirect,
            partsToSaveBeforeRedirect: (state) => state.partsToSaveBeforeRedirect,
            isVSDataIdle: (state) => isRequestIdle(state.requests.getVehicleSelectorData),
            isVSDataPending: (state) => isRequestPending(state.requests.getVehicleSelectorData),
            preventNavigationOnVehicleUpdate: (state) => state.preventNavigationOnVehicleUpdate,
        }),
        keepExistingQuoteFinal() {
            // if we are not prompting the user than we assume it should always be a new quote
            return this.promptAboutQuote ? this.userWantsExistingQuote : true;
        },
    },
    methods: {
        handleVehicleSelectSuccess({ gaEventAction = {}, vehicle = {} }) {
            /*
                the currentVehicle watcher in VehicleSelector.vue sets the forceUrl to false when a vehicle is added,
                so by the time the analytics() callback is called below, this.forceUrl is false.
                Setting it to a const here solves the problem, so we capture the original value at the time of selection.
                However, this race condition may rear its head again, due to watchers in other components,
                so it may be worth investigating a different approach to force-car where this race condition is eliminated.
            */
            const { forceUrl } = this;
            this.$emit('vehicle-selected', vehicle);
            this.$store.commit('vehicleSelector/setCurrentVehicle', vehicle);

            // We're doing this because the current vehicle is tightly coupled to a worksheet on the BE (for now).
            // If a new vehicle is selected, it immediately creates a new worksheet.
            this.$store.dispatch('miniQuote/getQuoteData');

            // if a part type selection is pending then we need to just update the vehicle selected and allow
            // the other component the ability to handle that selection without redirects interfering
            if (this.partTypePendingForceCar) {
                // set it back to false so component watcher knows it can attempt selection again
                this.$store.dispatch('vehicleSelector/setVsProp', { prop: 'partTypePendingForceCar', value: false });
                analytics({
                    event: GTM.Action.VEHICLE_SELECTION,
                    data: gaEventAction,
                });
                return;
            }

            // this needs to happen even if preventNavigationOnVehicleUpdate is true
            if (forceUrl) {
                let loading;

                if (!this.isSPA) {
                    loading = this.$fcoLoading();
                }

                if (this.saveLookupBeforeRedirect) {
                    this.$store.dispatch('partSelection/savePartLookup', { partTypes: this.partsToSaveBeforeRedirect, vehicle });
                }
                analytics({
                    event: GTM.Action.VEHICLE_SELECTION,
                    data: gaEventAction,
                    callback: () => {
                        document.dispatchEvent(new CustomEvent('vehicleSelector/forceSelected'));

                        // forceUrl can just a boolean to trigger the force car state without redirecting
                        if (forceUrl === true) {
                            if (loading) loading.remove();
                            return;
                        }

                        if (!this.isSPA) {
                            window.location.href = this.fcoUrl(forceUrl);
                            return;
                        }

                        this.$router.push(forceUrl);
                    },
                });
                return;
            }

            if (!this.preventNavigationOnVehicleUpdate) {
                analytics({
                    event: GTM.Action.VEHICLE_SELECTION,
                    data: gaEventAction,
                    callback: () => {
                        if (!this.isSPA) {
                            window.location.href = this.fcoUrl('/catalog/browse.html');
                            return;
                        }
                        this.$router.push('/catalog/browse');
                    },
                });
                return;
            }

            // for any pages where the VS is closed by default, we want to close it after a vehicle update is made
            if (!this.isOpenDefault) {
                this.$store.dispatch('vehicleSelector/setVsProp', { prop: 'isOpen', value: false });
            }

            analytics({
                event: GTM.Action.VEHICLE_SELECTION,
                data: gaEventAction,
            });
        },
        analytics,
    },
    created() {
        if (this.isVSDataIdle) this.$store.dispatch('vehicleSelector/getVehicleSelectorData');
    },
};
