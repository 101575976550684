import axios from 'axios';
import { fcoUrl } from '../../fcoModules/utilities';

// PRIVATE VARS/FUNCTIONS

// PUBLIC PROPERTIES

// PUBLIC METHODS
export const declineUserEmailUpdate = () => axios.post(fcoUrl('/user/updateEmail?declinedUpdate=true'));

export const getCurrentUser = () => axios.get(fcoUrl('/session/user'));

export const getEmployeeDetails = (userId) => axios.get(fcoUrl(`/user/employeeDetails?userId=${userId}`));

export const getIsAuthenticated = () => axios.get(fcoUrl('/session/isAuthenticated'));

export const saveUserEmail = (email) => axios.post(fcoUrl(`/user/updateEmail?email=${email}`));

export const saveUserPreferences = ({ userId, firstName, lastName, emailAddress, loginName, selectedStylesheet, languageCode }) => {
    const formData = Object.entries({ userId, firstName, lastName, emailAddress, loginName, selectedStylesheet, languageCode }).reduce(
        (data, [key, value]) => {
            data.append(key, value);
            return data;
        },
        new FormData()
    );
    return axios.post(fcoUrl('/user/savePreferences'), formData);
};

export const masquerade = (userId) => axios.post(fcoUrl('/admin/masquerade.html', { params: { key: userId } }));

export const stopMasquerade = () => axios.post(fcoUrl('/admin/masquerade.html'));

export const canCurrentUserEditUser = (userId) => axios.get(fcoUrl(`/user/canCurrentUserEditUser?userId=${userId}`));
