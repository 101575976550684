<template>
    <header id="top" class="fco-header main-header">
        <div class="fco-header-container d-flex">
            <HeaderBrandLink />
            <div class="fco-header-right d-flex d-print-none">
                <nav class="main-nav d-none d-lg-flex">
                    <FcoLink
                        v-for="navItem in navItems"
                        :key="navItem.id"
                        :to="navItem.route"
                        :href="navItem.url"
                        :id="navItem.id"
                        :class="['main-nav-item', navItem.class]"
                    >
                        <div class="no-wrap">{{ navItem.text1 }}</div>
                        {{ navItem.text2 }}
                    </FcoLink>
                </nav>
                <div class="ml-auto my-auto pl-3 d-lg-none">
                    <button class="nav-toggle" type="button" @click="isMobileMenuOpen = !isMobileMenuOpen">
                        <icon name="bars" color="green" />
                        {{ fcoM['rs.homeMain.menu'] }}
                    </button>
                </div>
            </div>
        </div>

        <transition name="mobile-menu" @before-enter="handleMobileMenuOpen" @after-leave="handleMobileMenuClosed">
            <div class="mobile-menu-container" v-show="isMobileMenuVisible" @click="handleMobileMenuClick" ref="mobileMenu">
                <div class="mobile-menu">
                    <div class="mobile-menu-header p-3 mb-2">{{ $store.state.currentShop.company.accountName }}</div>
                    <nav class="nav flex-column mobile-nav">
                        <FcoLink
                            v-for="navItem in navItems"
                            :key="navItem.id"
                            :to="navItem.route"
                            :href="navItem.url"
                            :id="navItem.id"
                            :class="['nav-link mb-2 mobile-nav-item', navItem.class]"
                        >
                            {{ navItem.mobileText }}
                        </FcoLink>
                    </nav>
                    <hr class="mobile-menu-divider" />
                    <admin-bar class="mobile-admin-bar px-0" :isDesktop="false" direction="vertical" />
                </div>
            </div>
        </transition>
    </header>
</template>

<script>
import { mapState } from 'vuex';
import { isRequestIdle, requestSettled } from '../store/request-status';
import Icon from './Icon.vue';
import AdminBar from './AdminBar.vue';
import HeaderBrandLink from './header/HeaderBrandLink.vue';
import FcoLink from './FcoLink.vue';

export default {
    name: 'MainNav',
    components: { HeaderBrandLink, AdminBar, Icon, FcoLink },
    data() {
        return {
            isMobileMenuOpen: false,
        };
    },
    computed: {
        ...mapState(['requests']),
        navItems() {
            return [
                {
                    isVisible: true,
                    route: '/catalog/browse',
                    url: this.fcoUrl('/catalog/browse.html'),
                    id: 'mainCatLookLink',
                    class: 'qaa-main-cat-look-link',
                    text1: this.fcoM['mainNav.catalogLookupLine1'],
                    text2: this.fcoM['mainNav.catalogLookupLine2'],
                    mobileText: this.fcoM['common.catalogLookup'],
                },
                {
                    isVisible: true,
                    route: '/stockorder',
                    url: this.fcoUrl('/stockorders/show'),
                    id: 'mainStockOrderLink',
                    class: 'qaa-main-stock-order-link',
                    text1: this.fcoM['mainNav.stockOrderLine1'],
                    text2: this.fcoM['mainNav.stockOrderLine2'],
                    mobileText: this.fcoM['common.stockOrder'],
                },
                {
                    isVisible: this.$store.getters.isWebUser,
                    route: '/customers',
                    url: this.fcoUrl('/customers/#/'),
                    id: 'mainManageCustLink',
                    class: 'qaa-main-manage-cust-link',
                    text1: this.fcoM['mainNav.manageCustomersLine1'],
                    text2: this.fcoM['mainNav.manageCustomersLine2'],
                    mobileText: this.fcoM['common.manageCustomers'],
                },
                {
                    isVisible: true,
                    route: '/quote/search',
                    url: this.fcoUrl('/worksheet/searchmain.html?iq=true&bca=1&type=b'),
                    id: 'mainQuotesOrdersLink',
                    class: 'qaa-main-quotes-orders-link',
                    text1: this.fcoM['mainNav.quotesAndOrdersLine1'],
                    text2: this.fcoM['mainNav.quotesAndOrdersLine2'],
                    mobileText: this.fcoM['rs.homeMain.quotesAndOrders'],
                },
                {
                    isVisible: true,
                    route: '/interchange',
                    url: this.fcoUrl('/interchange'),
                    id: 'mainInterchangeLink',
                    class: 'qaa-main-interchange-link',
                    text1: this.fcoM['mainNav.partInterchangeLine1'],
                    text2: this.fcoM['mainNav.partInterchangeLine2'],
                    mobileText: this.fcoM['common.partInterchange'],
                },
            ].filter(({ isVisible }) => isVisible);
        },
        isMobileMenuVisible() {
            return this.isMobileMenuOpen && this.$fcoMq.isMdDown;
        },
    },
    watch: {
        '$route.path': {
            immediate: true,
            handler() {
                this.isMobileMenuOpen = false;
            },
        },
    },
    methods: {
        async handleMobileMenuOpen() {
            if (this.$refs.mobileMenu.parentElement !== document.body) {
                await this.$nextTick();
                document.body.appendChild(this.$refs.mobileMenu);
            }
            document.body.classList.add('mobile-menu-open');
        },
        handleMobileMenuClosed() {
            document.body.classList.remove('mobile-menu-open');
        },
        handleMobileMenuClick({ target }) {
            if (target === this.$refs.mobileMenu) {
                this.isMobileMenuOpen = false;
            }
        },
    },
    async created() {
        if (isRequestIdle(this.requests.getCurrentShop)) this.$store.dispatch('getCurrentShop');
        await requestSettled(() => this.requests.getCurrentShop);
    },
    beforeDestroy() {
        if (this.$refs.mobileMenu.parentElement === document.body) {
            document.body.removeChild(this.$refs.mobileMenu);
        }
    },
};
</script>

<style lang="scss" scoped>
@import '~scssVariables/mixins';
@import '~scssVariables/config';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.main-header {
    padding-left: $padding;
    padding-right: $padding;
}

.fco-header-container {
    @include container-wide;

    @include respond-up-to(sm) {
        padding: 0;
    }
}

.main-nav {
    margin: auto 0 auto auto;
    justify-content: flex-end;
}

.main-nav-item {
    display: block;
    min-width: 2vw;
    margin: 0 0.85em;
    padding: 0.25rem 0.85em;
    text-align: center;
    font-family: $font-oswald;
    font-size: 1.75vw;
    letter-spacing: 0.05em;
    line-height: 1.2;
    text-decoration: none;
    color: $white;

    @include respond(xl) {
        font-size: 1.5rem;
    }

    &:last-child {
        margin-right: 0;
        padding-right: 0;
    }

    &:hover,
    &:focus {
        color: $yellow;
        text-decoration: none;
    }
}

.nav-toggle {
    border-radius: 0.25rem;
    font-size: 0.625rem;
    padding: 0.25rem 0.3125rem;
    margin: 0;
    text-transform: uppercase;

    &,
    &:hover,
    &:focus {
        background: $white;
        color: $green;
    }

    .icon.bars {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 auto;
    }
}

.mobile-menu-open {
    overflow: hidden;
}

.mobile-menu-container {
    background-color: $modal;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $z-index-drawer;
    display: flex;
    flex-direction: column;
}

.mobile-menu {
    width: 90%;
    max-width: 400px;
    align-self: flex-end;
    height: 100%;
    background-color: $black;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .mobile-menu-header {
        color: $gray-300;
        font-size: 0.8125rem;
        background: #000;
    }

    .mobile-nav-item {
        font-size: 1.125rem;
        color: $white;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            color: $yellow;
        }
    }

    .mobile-menu-divider {
        border-color: $gray-600;
    }

    .mobile-admin-bar {
        border-bottom: 0 none;
    }

    .admin-bar-container {
        padding: 0;
    }

    .admin-bar-account-name {
        display: none;
    }

    .shop-select {
        margin: 0.5rem 1rem;
        width: auto;

        .dropdown-menu {
            border-color: $gray-600;
            box-shadow: 0 2px 6px rgba(255, 255, 255, 0.2);
        }
    }
}

.mobile-menu-enter-active,
.mobile-menu-leave-active {
    transition: background-color 0.2s ease;

    .mobile-menu {
        transition: transform 0.3s ease;
    }
}

.mobile-menu-enter,
.mobile-menu-leave-to {
    background-color: transparent;

    .mobile-menu {
        transform: translate3d(100%, 0, 0);
    }
}
</style>
