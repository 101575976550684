import { mapGetters, mapState } from 'vuex';
import { isRequestIdle, requestSettled } from '@/common/store/request-status';

export default {
    computed: {
        ...mapState(['requests', 'features']),
        ...mapGetters(['isFeatureDisabled']),
        isEntSearchEnabled() {
            return !this.isFeatureDisabled('entsearch');
        },
        isI18nEnabled() {
            return !this.isFeatureDisabled('language_selection');
        },
    },
    methods: {
        async loadFeatureFlags() {
            if (isRequestIdle(this.requests.getFeatures)) this.$store.dispatch('getFeatures');

            // We may have cached feature flags already loaded. In that case, we don't need to await the request to get them.
            // Any updates to feature flags will be loaded in the background, and the cache will be updated accordingly.
            if (this.features.length) return;

            await requestSettled(() => this.requests.getFeatures);
        },
    },
    mounted() {
        this.loadFeatureFlags();
    },
};
