var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "FcoLink",
    {
      staticClass: "fco-header-brand",
      attrs: {
        to: _vm.homeRoute,
        href: _vm.fcoUrl(_vm.isSMSUser ? "/catalog/browse.html" : "/"),
      },
    },
    [
      _c("picture", [
        _c("source", {
          attrs: {
            media: "(max-width:767px)",
            srcset: "/FirstCallOnline/images/fco-logo-white-notag.png",
          },
        }),
        _vm._v(" "),
        _c("source", {
          attrs: {
            media: "(min-width:768px)",
            srcset: "/FirstCallOnline/images/fco-logo-notag.png",
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "qaa-header-logo",
          attrs: {
            src: "/FirstCallOnline/images/fco-logo-notag.png",
            alt: _vm.fcoM["rs.homeMain.fcoLogo"],
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }