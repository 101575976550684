<template>
    <footer>
        <div class="content py-5">
            <div class="footer-grid d-print-none mb-5">
                <div class="footer-logo-container">
                    <img class="footer-logo" src="/images/fco-logo-white.png" />
                </div>
                <nav>
                    <ul class="footer-nav">
                        <li>
                            <a
                                href="http://www.3eonline.com/eeeOnlinePortal/DesktopDefault.aspx?id=GFAirWx2BmohQvleYq99kSzCLhParjXcl%2b1aedzg6NTOC%2bBryI7a4gKGBZBru%2fbE&amp;tabid=90"
                                class="qaa-footer-msds"
                            >
                                {{ fcoM['rs.home.msds'] }}
                            </a>
                        </li>
                        <li class="d-none d-md-block">
                            <FcoLink to="/credit" :href="fcoUrl('/credit.html')" :class="['qaa-footer-credit-application', creditAppGtmClass]">
                                {{ fcoM['common.creditApplication'] }}
                            </FcoLink>
                        </li>
                        <li class="d-none d-lg-block">
                            <FcoLink class="qaa-footer-team-viewer" to="/team-viewer" :href="fcoUrl('/teamviewer.html')">
                                {{ fcoM['rs.home.teamViewer'] }}
                            </FcoLink>
                        </li>
                        <li v-if="userCanViewStatements">
                            <FcoLink
                                class="qaa-footer-billing-statements"
                                to="/admin/statements"
                                :href="fcoUrl('/statementlookup/statementview.html')"
                            >
                                <span v-if="user.earnbackEligible"> {{ fcoM['common.statementsAndEarnbacks'] }}</span>
                                <span v-else> {{ fcoM['common.billingStatements'] }}</span>
                            </FcoLink>
                        </li>
                        <li>
                            <FcoLink :class="['qaa-footer-contactus contactUs', contactGtmClass]" to="/help" :href="fcoUrl('/faq.html')">
                                {{ fcoM['common.contactUs'] }}
                            </FcoLink>
                        </li>
                    </ul>
                    <ul v-if="isAuthorizedUser" class="footer-nav">
                        <li>
                            <DotCmsLink file-name="first-call-drop-box-slip.pdf" class="qaa-footer-drop-box-slip" target="_blank">{{
                                fcoM['rs.home.dropBoxSlip']
                            }}</DotCmsLink>
                        </li>
                        <li>
                            <DotCmsLink file-name="FCO-INSPECTION_FORM.pdf" class="qaa-footer-vehicle-inspection-form" target="_blank">{{
                                fcoM['rs.home.vehicleInspectionForm']
                            }}</DotCmsLink>
                        </li>
                        <li v-if="userCanViewLaborClaims">
                            <FcoLink class="qaa-footer-labor-claims" to="/admin/labor-claims" :href="fcoUrl('/admin/laborClaims/')">
                                {{ fcoM['common.laborClaims'] }}
                            </FcoLink>
                        </li>
                    </ul>
                </nav>
                <div class="text-right">
                    <LanguageSelectButton v-if="isI18nEnabled" button-class="footer-language-select" label-class="footer-language-select-label" />
                </div>
            </div>
            <div class="text-xs footer-legal">
                <div class="med-gray mb-3">{{ copyrightString }}<br />{{ versionString }}</div>
                <div class="footer-legal-nav d-print-none">
                    <FcoLink class="qaa-footer-terms-conditions" to="/terms" :href="fcoUrl('/legal/terms/index.html')">{{
                        fcoM['common.termsAndConditions']
                    }}</FcoLink>
                    <FcoLink class="qaa-footer-privacy-policy" to="/privacy" :href="fcoUrl('/legal/policy/index.html')">
                        {{ fcoM['common.privacyPolicy'] }}
                    </FcoLink>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { isRequestIdle } from '../store/request-status';
import { stringFormat } from '../../fcoModules/utilities';
import LanguageSelectButton from './LanguageSelectButton.vue';
import FcoLink from './FcoLink.vue';
import DotCmsLink from './cmsContent/DotCmsLink.vue';
import featureFlagsMixin from '../mixins/featureFlagsMixin';

export default {
    name: 'Footer',
    mixins: [featureFlagsMixin],
    computed: {
        ...mapGetters(['isAuthorizedUser', 'userCanViewStatements', 'userCanViewLaborClaims']),
        ...mapState({
            user: (state) => state.user,
            requests: (state) => state.requests,
            version: (state) => state.version,
        }),
        copyrightString() {
            return stringFormat(this.fcoM['footer.copyright'], new Date().getFullYear());
        },
        versionString() {
            const node = this.version.node ? `(${this.version.node})` : '';
            return `v. ${this.version.fcoVersion}${node}, OCAT:${this.version.ocatVersion}`;
        },
        contactGtmClass() {
            if (this.isAuthorizedUser) {
                return 'gtm-authorized-contact';
            }
            return 'gtm-public-contact';
        },
        creditAppGtmClass() {
            if (this.isAuthorizedUser) {
                return 'gtm-authorized-credit';
            }
            return 'gtm-public-credit';
        },
    },
    created() {
        if (isRequestIdle(this.requests.getAppData)) this.$store.dispatch('getAppData');
        if (this.isAuthorizedUser && isRequestIdle(this.requests.getUser)) this.$store.dispatch('getUser');
    },
    components: { LanguageSelectButton, FcoLink, DotCmsLink },
};
</script>

<style lang="scss" scoped>
@import '~scssVariables/config';
@import '~scssVariables/mixins';

footer {
    height: auto;
    background-color: $black;
}

.footer-nav {
    flex-wrap: wrap;
    align-items: baseline;
    gap: 1rem 2rem;
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;

    li {
        margin: 0 0 1rem;
    }

    @include respond(sm) {
        display: flex;

        li {
            margin: 0;
        }
    }

    @include respond(md) {
        justify-content: center;
    }
}

.footer-nav a,
.footer-legal a {
    color: $white;

    &:not(:is(:hover, :focus)) {
        text-decoration: none;
    }
}

.footer-grid {
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 2rem 3rem;

    @media print {
        display: none;
    }

    @include respond(md) {
        grid-template-columns: 212px 1fr 212px;
    }
}

.footer-logo-container {
    @include respond-up-to(md) {
        grid-row: 2;
        grid-column-end: span 2;
    }
}

.footer-logo {
    width: 212px;
}

.footer-legal {
    @include respond(md) {
        text-align: center;
    }
}

.footer-legal-nav {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem 1rem;
    justify-content: flex-start;

    @include respond(md) {
        justify-content: center;
    }
}

::v-deep .footer-language-select {
    border: 1px solid $gray-500;
    background-color: transparent;
    color: $gray-300;
    font-weight: normal;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .footer-language-select-label {
        color: $gray-550;
        transition: inherit;
    }

    &:hover,
    &:active,
    &.active,
    &:focus-visible {
        color: #000;
        background-color: $gray-300;
        border-color: $gray-300;

        .footer-language-select-label {
            color: $gray-700;
        }
    }

    &:active,
    &:focus-visible,
    &.active {
        box-shadow: 0 0 0 2px rgba(242, 242, 242, 0.5);
    }
}
</style>
