var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "a",
    {
      attrs: {
        href: _vm.href,
      },
    },
    [
      _vm._t("default", function () {
        return [_vm._v(_vm._s(_vm.fileName))]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }