import axios from 'axios';
import { fcoUrl } from '../../fcoModules/utilities';
import { PartTypeSearchLimit, PartTypeSearchSort, PlatformId } from '../constants/ordering';

// PRIVATE VARS/FUNCTIONS
const partTypeRESTPath = fcoUrl('/parttypenavigation');
const partTypeMiniRESTPath = fcoUrl('/parttype/mini');
const packageRESTPath = fcoUrl('/packagerest');

// PUBLIC METHODS
// PartType
export const getMostPopular = () => axios.get(`${partTypeRESTPath}/mostpopular.json`);
export const getAll = () => axios.get(`${partTypeRESTPath}/allparttypes.json`);
export const drilldown = (parentId, isPackageJob) => {
    const endPath = isPackageJob ? 'drilldownpj' : 'drilldown';
    return axios.get(`${partTypeRESTPath}/${endPath}`, { params: { parentId } });
};

export const drilldownV2 = (parentId, vehicle, isPackageJob) => {
    if (isPackageJob) return axios.get(`${partTypeRESTPath}/drilldownpj`, { params: { parentId } });
    return axios.post(`${partTypeRESTPath}/v2/drilldown`, { parentId, vehicle });
};
export const searchPartTypes = ({ query, max = PartTypeSearchLimit.FIRST_15, sort = PartTypeSearchSort.POPULAR }) =>
    axios.get(fcoUrl('/search/keyword/searchPartType.json'), {
        params: {
            query,
            max,
            sort,
        },
    });

export const getPartTypeNode = (parentId, platformId, partTypeId) =>
    axios.get(fcoUrl(`${partTypeRESTPath}/search`), {
        params: {
            parentId,
            platformId,
            partTypeId,
        },
    });

export const getRelatedPartTypes = (partTypeId, vehicleInfo, shopId, platformId = PlatformId.ALL_PART_TYPES) =>
    axios.post(fcoUrl('/parttype/mini/relatedPartTypes'), {
        partTypeId,
        vehicleInfo,
        shopId,
        platformId,
    });

// Package
// get brand list for partType
export const getBrands = (partTypeId) => axios.get(`${packageRESTPath}/brands.json`, { params: { partTypeId } });
// get package job list
export const getShopJobs = () => axios.get(`${packageRESTPath}/shopjobs.json`);
// get package job detail
export const getJob = (jobId) => axios.get(`${packageRESTPath}/customPackageJob.json`, { params: { jobId } });
// update package
export const updateJob = (currentPackageJob) => axios.post(`${packageRESTPath}/customPackageJob.json`, currentPackageJob);
// delete package
export const deleteJob = (jobId) => axios.delete(`${packageRESTPath}/customPackageJob.json`, { params: { jobId } });

// Submit
// get Selected PartTypes From Session
export const getSelectedPartTypesFromSession = () => axios.get(fcoUrl('/parttype/node/selected'));
// clear PartTypes
export const clearPartTypes = () => axios.post(`${partTypeMiniRESTPath}/clearPartTypes`);
// clear And SelectPartType
export const clearAndSelectPartType = (selectPartTypes) => axios.post(`${partTypeMiniRESTPath}/clearAndSelectPartType`, selectPartTypes);
