/* eslint no-shadow: ['error', { 'allow': ['state', 'getters'] }] */
/**
 * The base store for the FCO multi-page application.
 * All apps within FCO can leverage this store to get core data about the app, user, account, etc.
 *
 * If an app needs to add modules to this store, dynamic module registration can be leveraged.
 * https://vuex.vuejs.org/guide/modules.html#dynamic-module-registration
 *
 * Individual store properties (state, mutations, actions, etc.) are exported mainly for testing purposes.
 * Unless our backend data structure dictates otherwise, they should not be imported into another store for the sake of extending this one.
 * We should try to keep app-level state in one place.
 */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import delay from 'fco/src/utils/delay';

import { fcoUrl } from '@/fcoModules/utilities';
import { selectShop } from '@/common/services/shopService';
import { createRequestStatus, requestSettled, trackRequestStatus, updateRequestStatus } from './request-status';
import { declineUserEmailUpdate, getCurrentUser, masquerade, saveUserEmail, saveUserPreferences, stopMasquerade } from '../services/userService';
import { saveCompanyPreferences } from '../services/companyService';
import { getFeatures } from '../services/featuresService';
import { Locale, Role } from '../constants/user';

// Store Modules
import vehicleSelector from './modules/vehicleSelector';
import miniQuote from './modules/miniQuote';
import stockOrderQuickAdd from './modules/stockOrderQuickAdd';
import opp from './modules/opp';
import kits from './modules/kits';
import partSelection from './modules/partSelection';
import { getStorage, getStorageItem, removeStorageItem, setStorageItem, StorageKey } from './store-utils';
import { getCMSData } from '../services/cmsService';

Vue.use(Vuex);

const setAuthorizationHeader = (authData) => {
    if (!authData) {
        delete axios.defaults.headers.common.Authorization;
    } else {
        const { access_token: accessToken } = authData;
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
};

const authWithExpiration = (auth) => {
    if (!auth) return null;
    const now = Date.now();
    const fiveMinutes = 300000;
    return {
        ...auth,
        accessExpiresOn: now + auth.access_expiration - fiveMinutes,
        refreshExpiresOn: now + auth.refresh_expiration - fiveMinutes,
    };
};

const cachedAuth = getStorageItem(StorageKey.AUTH);
setAuthorizationHeader(cachedAuth);

const getDefaultUser = () => ({
    authenticated: false,
    carCustomer: false,
    customerType: {
        description: '',
        name: '',
    },
    customerTypeSms: false,
    emailAddress: '',
    emailUpdateRequired: false,
    hideCosts: false,
    hideStorePhoneNumber: false,
    firstName: '',
    lastName: '',
    loginName: '',
    masquerade: false,
    partsPayoffAccessible: false,
    passwordChangeRequired: false,
    shopReferralEligible: false,
    teamMember: false,
    userId: null,
    userPreference: {
        languageCode: Locale.EN,
        catalogNavigationTab: 1,
        selectedStylesheet: 1,
        selectedShopId: 0,
    },
    group: '',
    cxmlCallbackUrl: '',
});

const getDefaultCompany = () => ({
    demo: false,
    id: 0,
    address: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        flatAddress: '',
        completeAddress: false,
        notEmpty: false,
        combinedStreetAddress: '',
    },
    companyPreference: {
        garageBayIdVisible: false,
        garageBayIdRequired: false,
        vehicleFleetIdVisible: false,
        vehicleFleetIdRequired: false,
        poVisible: false,
        poRequired: false,
        companyId: 0,
        id: 0,
        poValidationMask: '',
    },
    accountStatus: '',
    shopRequests: [
        {
            garageIdRequired: false,
            garageBayIdVisible: false,
            poRequired: false,
            poVisible: false,
            vehicleFleetIdRequired: false,
            vehicleFleetIdVisible: false,
            requested: false,
            approved: false,
            pending: false,
            declined: false,
            arAccountNumber: 0,
            shopAddress: {
                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: '',
                flatAddress: '',
                completeAddress: false,
                notEmpty: false,
                combinedStreetAddress: '',
            },
            laborRate: 0,
            pricingTypeModification: 0,
            taxRateLabor: 0,
            taxRateParts: 0,
            companyId: 0,
            notes: [
                {
                    id: 0,
                    text: '',
                    createdByUser: {
                        firstName: '',
                        lastName: '',
                        loginName: '',
                    },
                    createDateTime: '',
                    internal: false,
                    shopRequestId: 0,
                },
            ],
            id: 0,
            pricingMethod: '',
            pricingType: '',
            requestStatus: '',
            customerLocationId: '',
            miscInfo: '',
            poValidationMask: '',
            shopFax: '',
            shopName: '',
            shopPhone: '',
            createDateTime: '',
        },
    ],
    accountName: '',
    arGroupCode: '',
    shopReferralEligible: false,
    partsPayoffVisible: false,
    paymentMethod: '',
});

const getDefaultShop = () => ({
    shopAddress: {},
    laborRate: 0,
    taxRateLabor: 0,
    taxRateParts: 0,
    company: {
        accountName: '',
        demo: false,
        address: {},
        companyPreference: {},
        paymentMethod: '',
    },
    shopCustomers: [],
    id: 0,
    arAccountNumber: 0,
    activeDevices: [],
    devices: [],
    deviceAssociations: [],
    shopPreference: {},
    shopStatus: '',
    packages: [],
    shopName: '',
    shopPhone: '',
    shopFax: '',
    city: '',
    state: '',
    address: '',
    zip: '',
    customerLocationId: '',
    laborIntegrationActive: false,
});

const getDefaultStore = () => ({
    storeId: 0,
    address: '',
    address2: '',
    shortAddress: '',
    city: '',
    displayCity: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
    latitude: 0,
    longitude: 0,
    deliveryFleetManagement: false,
});

const getDefaultCmsData = () => ({ url: '', token: '' });

export const state = {
    isSPA: false,
    auth: cachedAuth,
    csrfObject: {
        parameterName: '_csrf',
        headerName: 'X-CSRF-TOKEN',
        token: '',
    },
    googleMapsKey: '',
    imageServerUrl: 'https://images.firstcallonline.com',
    promoImagesUrl: '',
    teamviewerAndroidURL: '',
    teamviewerIosURL: '',
    teamviewerWindowsURL: '',
    version: {
        copyright: `Copyright © 2011-${new Date().getFullYear()}`,
        fcoVersion: '',
        node: '',
        ocatVersion: '',
    },
    user: getStorageItem(StorageKey.USER) || getDefaultUser(),
    masqueradeSource: getStorage().getItem(StorageKey.MASQUERADE_SOURCE),
    shops: [],
    currentShop: getDefaultShop(),
    shopRequests: [],
    currentStore: getDefaultStore(),
    page: '',
    USStates: [],
    jobTitles: [],
    languageCodes: [],
    customerTypes: [],
    company: getDefaultCompany(),
    scrollToOffset: 0,
    profile: '',
    cmsData: getDefaultCmsData(),
    features: getStorageItem(StorageKey.FEATURES) || [],
    tsm: {
        tsmEmailAddress: '',
        tsmFirstName: '',
        tsmLastName: '',
        tsmNumber: 0,
    },
    requests: {
        getAppData: createRequestStatus(),
        getUser: createRequestStatus(),
        getCurrentShop: createRequestStatus(),
        getCurrentStore: createRequestStatus(),
        getTSM: createRequestStatus(),
        getFeatures: createRequestStatus(),
        getCMSData: createRequestStatus(),
        getUSStates: createRequestStatus(),
        getJobTitles: createRequestStatus(),
        getLanguageCodes: createRequestStatus(),
        getCustomerTypes: createRequestStatus(),
        getShopRequests: createRequestStatus(),
        getCompany: createRequestStatus(),
    },
};

const mutations = {
    updateRequestStatus,
    setUSStates(state, value) {
        state.USStates = value;
    },
    setJobTitles(state, value) {
        state.jobTitles = value;
    },
    setLanguageCodes(state, value) {
        state.languageCodes = value;
    },
    setCustomerTypes(state, value) {
        state.customerTypes = value;
    },
    setCompany(state, data) {
        state.company = data;
    },
    setAuthTokens(state, auth) {
        state.auth = auth || null;
        setAuthorizationHeader(state.auth);
        if (!state.auth) {
            removeStorageItem(StorageKey.AUTH);
        } else {
            setStorageItem(StorageKey.AUTH, state.auth);
        }
    },
    setUser(state, user) {
        state.user = user?.userId ? user : getDefaultUser();
        setStorageItem(StorageKey.USER, state.user);
    },
    setMasqueradeSource(state, masqueradeSource) {
        state.masqueradeSource = masqueradeSource;
        if (!masqueradeSource) removeStorageItem(StorageKey.MASQUERADE_SOURCE);
        else setStorageItem(StorageKey.MASQUERADE_SOURCE, masqueradeSource);
    },
    setShops(state, shops) {
        state.shops = shops || [];
    },
    setFeatures(state, features) {
        state.features = features || [];
        // Amount of time (ms) to cache features on the FE before they are considered expired.
        // Features will still be loaded in the background, even when cached, but this
        // allows us to immediately load up the correct UI features without having to wait
        // for the features request on subsequent page loads.
        const fifteenMinutes = 900000;
        setStorageItem(StorageKey.FEATURES, state.features, fifteenMinutes);
    },
    setCurrentShop(state, value) {
        state.currentShop = { ...value };
    },
    setShopRequests(state, shopRequests) {
        state.shopRequests = shopRequests || [];
    },
    setCurrentStore(state, value) {
        state.currentStore = { ...value };
    },
    setAppData(
        state,
        { csrfObject, googleMapsKey, imageServerUrl, version, promoImagesUrl, profile, teamviewerAndroidURL, teamviewerIosURL, teamviewerWindowsURL }
    ) {
        state.csrfObject = csrfObject;
        state.googleMapsKey = googleMapsKey;
        state.imageServerUrl = imageServerUrl;
        state.version = version;
        state.promoImagesUrl = promoImagesUrl;
        state.profile = profile;
        state.teamviewerAndroidURL = teamviewerAndroidURL;
        state.teamviewerIosURL = teamviewerIosURL;
        state.teamviewerWindowsURL = teamviewerWindowsURL;
        // In the multi-page Spring app, we need the CSRF with most of our requests. If a CSRF token was returned, assign it to the default axios header config.
        if (!state.isSPA && csrfObject?.token) {
            axios.defaults.headers.common['X-CSRF-Token'] = csrfObject.token;
        }
    },
    setCurrentPage(state, pageName) {
        state.page = pageName;
    },
    setHideCosts(state, value) {
        state.user.hideCosts = value;
        setStorageItem(StorageKey.USER, state.user);
    },
    updateUserPreferences(
        state,
        {
            firstName = state.user.firstName,
            lastName = state.user.lastName,
            emailAddress = state.user.emailAddress,
            loginName = state.user.loginName,
            selectedStylesheet = state.user.userPreference?.selectedStylesheet,
            languageCode = state.user.userPreference?.languageCode,
            selectedShopId = state.user.userPreference?.selectedShopId,
        } = {}
    ) {
        state.user = {
            ...state.user,
            firstName,
            lastName,
            emailAddress,
            loginName,
            userPreference: {
                ...state.user.userPreference,
                selectedStylesheet,
                languageCode,
                selectedShopId,
            },
        };
        setStorageItem(StorageKey.USER, state.user);
    },
    updateCompanyPreferences(state, { accountName, demo, address, companyPreference }) {
        state.currentShop.company = {
            ...state.currentShop.company,
            accountName,
            address,
            demo,
            companyPreference,
        };
    },
    setUserEmailAddress(state, emailAddress) {
        state.user.emailAddress = emailAddress;
        setStorageItem(StorageKey.USER, state.user);
    },
    setUserEmailUpdateRequired(state, emailUpdateRequired) {
        state.user.emailUpdateRequired = emailUpdateRequired;
    },
    setScrollToOffset(state, value) {
        state.scrollToOffset = Number(value) || 0;
    },
    setCMSData(state, data) {
        state.cmsData = data;
    },
    setTSM(state, data) {
        state.tsm = data;
    },
};

let refreshTimeout;
const actions = {
    async login({ commit, dispatch }, loginForm) {
        clearTimeout(refreshTimeout);
        const { data } = await axios.post(fcoUrl('/auth/rest/login'), loginForm);
        commit('setAuthTokens', authWithExpiration(data));
        dispatch('startRefreshTimer');
    },
    async refreshAuth({ state, commit, dispatch, getters }, abortWhenValid = false) {
        const refreshLockKey = 'fcoRefreshTokenLock';

        clearTimeout(refreshTimeout);

        // A mutex-style lock to prevent multiple simultaneous requests to refresh the token
        // If this is true, that means something else - either in this tab/window or another one - is already refreshing the token
        if (localStorage.getItem(refreshLockKey)) {
            // Retry the refresh request after a short delay.
            await delay(100);
            // Only refresh if the token hasn't already been updated.
            return dispatch('refreshAuth', true);
        }

        if (getters.isAuthExpired || !abortWhenValid) {
            try {
                localStorage.setItem(refreshLockKey, 'true');
                const { data } = await axios.get(fcoUrl('/auth/rest/refresh'), { headers: { Authorization: `Bearer ${state.auth.refresh_token}` } });
                commit('setAuthTokens', authWithExpiration(data));
            } finally {
                localStorage.removeItem(refreshLockKey);
            }
        }

        dispatch('startRefreshTimer');
    },
    logout({ commit, dispatch }) {
        clearTimeout(refreshTimeout);
        commit('vehicleSelector/resetVehicleData');
        dispatch('partSelection/clearLookupDetails');
        commit('setUser', getDefaultUser());
        commit('setAuthTokens', null);
    },
    startRefreshTimer({ state, dispatch }) {
        clearTimeout(refreshTimeout);

        const now = Date.now();
        const { accessExpiresOn, refreshExpiresOn } = state.auth || {};

        // If we have no refresh token or it's expired, bail out here
        if (!refreshExpiresOn || refreshExpiresOn <= now) return;

        const timeUntilAuthExpiration = accessExpiresOn - Date.now();
        refreshTimeout = setTimeout(() => {
            dispatch('refreshAuth');
        }, timeUntilAuthExpiration);
    },
    async getUser({ state, commit, getters, dispatch }) {
        const response = await trackRequestStatus(commit, 'getUser', getCurrentUser());
        const userWithShops = response?.data || {};

        // The getCurrentUser() endpoint currently returns as 200 for unauthenticated users
        // but returns text/html because it's actually redirecting the request to the login page.
        // If no userId, it means we don't have a valid user, so treat it like a 401 and clear any cached session.
        // This is only necessary for the MPA, where we rely heavily on the BE for session data.
        if (!state.isSPA && !userWithShops?.userId && getters.isAuthorizedUser) {
            dispatch('logout');
            return;
        }

        // TODO: Shops currently come back with the user, but that will soon change with RWD-12542 and other related tasks
        const { shops = [], ...user } = userWithShops;
        commit('setUser', user);
        commit('setShops', shops);
    },
    async saveUserPreferences(
        { commit, state: { user } },
        {
            firstName = user.firstName,
            lastName = user.lastName,
            emailAddress = user.emailAddress,
            loginName = user.loginName,
            selectedStylesheet = user.userPreference?.selectedStylesheet,
            languageCode = user.userPreference?.languageCode,
        }
    ) {
        const userPreferencesData = {
            firstName,
            lastName,
            emailAddress,
            loginName,
            selectedStylesheet,
            languageCode,
        };
        await saveUserPreferences({ ...userPreferencesData, userId: user.userId });
        commit('updateUserPreferences', userPreferencesData);
    },
    async saveCompanyPreferences({ commit }, companyData) {
        await saveCompanyPreferences(companyData);
        commit('updateCompanyPreferences', companyData);
    },
    async getFeatures({ commit }) {
        const response = await trackRequestStatus(commit, 'getFeatures', getFeatures());
        commit('setFeatures', response?.data || []);
    },
    async getCurrentShop({ commit }) {
        const response = await trackRequestStatus(commit, 'getCurrentShop', axios.get(fcoUrl('/current/shop')));
        commit('setCurrentShop', response?.data || getDefaultShop());
    },
    async getShopRequests({ commit }, shopId) {
        const response = await trackRequestStatus(commit, 'getShopRequests', axios.get(fcoUrl(`/shop/${shopId}/company/shopRequests`)));
        commit('setShopRequests', response?.data || []);
    },
    async getCompany({ commit }, shopId) {
        const response = await trackRequestStatus(commit, 'getCompany', axios.get(fcoUrl(`/shop/${shopId}/company`)));
        commit('setCompany', response?.data || getDefaultCompany());
    },
    async getTSM({ commit }) {
        const { data: tsm } = await trackRequestStatus(commit, 'getTSM', axios.get(fcoUrl('/current/tsm')));
        commit('setTSM', tsm);
    },
    async getCurrentStore({ commit }) {
        const response = await trackRequestStatus(commit, 'getCurrentStore', axios.get(fcoUrl('/current/store')));
        commit('setCurrentStore', response?.data || getDefaultStore());
    },
    async getAppData({ commit }) {
        const { data } = await trackRequestStatus(commit, 'getAppData', axios.get(fcoUrl('/current/static')));
        commit('setAppData', data);
    },
    async getCMSData({ commit }) {
        const cmsData = await trackRequestStatus(commit, 'getCMSData', getCMSData());
        commit('setCMSData', cmsData || getDefaultCmsData());
    },
    getUSStates({ commit }) {
        const url = fcoUrl('/constants?states=true');

        trackRequestStatus(commit, 'getUSStates', axios.get(url)).then((response) => {
            commit('setUSStates', response.data.states);
        });
    },
    getJobTitles({ commit }) {
        const url = fcoUrl('/constants?jobTitles=true');

        trackRequestStatus(commit, 'getJobTitles', axios.get(url)).then((response) => {
            commit('setJobTitles', response.data.jobTitles);
        });
    },
    getLanguageCodes({ commit }) {
        const url = fcoUrl('/constants?languageCodes=true');

        trackRequestStatus(commit, 'getLanguageCodes', axios.get(url)).then((response) => {
            commit('setLanguageCodes', response.data.languageCodes);
        });
    },
    getCustomerTypes({ commit }) {
        const url = fcoUrl('/constants?customerTypes=true');

        trackRequestStatus(commit, 'getCustomerTypes', axios.get(url)).then((response) => {
            commit('setCustomerTypes', response.data.customerTypes);
        });
    },

    async toggleCosts({ state, commit }) {
        const hideCosts = !state.user.hideCosts;
        try {
            // Store the value in session.
            const form = new FormData();
            form.append('hideCosts', hideCosts);
            axios.post(fcoUrl('/worksheet/rest/hideCosts'), form);
        } catch (error) {
            // This is not a critical request. Things will still function as expected. Just let this fail silently.
        }
        commit('setHideCosts', hideCosts);
    },
    async saveUserEmailAddress({ commit }, email) {
        await saveUserEmail(email);

        commit('setUserEmailAddress', email);
        commit('setUserEmailUpdateRequired', false);
    },
    async declineUserEmailUpdate({ commit }) {
        await declineUserEmailUpdate();
        commit('setUserEmailUpdateRequired', false);
    },
    async refetchUserCriticalData({ dispatch, state }) {
        const requests = ['getFeatures', 'getCurrentShop', 'getCurrentStore'];
        requests.forEach((requestName) => dispatch(requestName));
        await Promise.all(requests.map((requestName) => requestSettled(() => state.requests[requestName])));
    },
    async masquerade({ dispatch, commit, state }, userId) {
        await masquerade(userId);
        // need to make sure FE vehicle data persistence clears out any selected vehicles anytime masquerade happens
        await dispatch('vehicleSelector/clearCurrentVehicle', null, { root: true });
        // Set the current page so we can go back once the user stops masquerading
        const currentPage = state.isSPA ? window.location.hash : window.location.href;
        commit('setMasqueradeSource', currentPage);
        await dispatch('getUser');
        // FIXME (SPA): this doesn't work for JWT authentication. We'll need to handle this differently (i.e. separate token) for use in the SPA
        await dispatch('refetchUserCriticalData');
    },
    async stopMasquerade({ dispatch }) {
        await stopMasquerade();
        // need to make sure FE vehicle data persistence clears out any selected vehicles anytime masquerade ends
        await dispatch('vehicleSelector/clearCurrentVehicle', null, { root: true });
        await dispatch('getUser');
        await dispatch('refetchUserCriticalData');
    },
    async selectShop({ commit }, selectedShopId) {
        await selectShop(selectedShopId);
        commit('updateUserPreferences', { selectedShopId });
    },
};

const cxmlCustomerTypes = new Set([
    'JDBYRIDER',
    'COKE',
    'SONIC',
    'WASTEMANAGEMENT',
    'SSA',
    'CARVANA',
    'TEAMCARCARE',
    'BIGBRANDTIRE',
    'VIPSHOPMANAGEMENT',
    'DRIVE',
]);

const getters = {
    isAuthExpired: ({ auth }) => !auth || auth?.accessExpiresOn < Date.now(),
    isRefreshExpired: ({ auth }) => !auth || auth?.refreshExpiresOn < Date.now(),
    // Unless the user is masquerading, if password reset is required, user should not be allowed full access
    isAuthorizedUser: ({ user }) => user.authenticated && (!user.passwordChangeRequired || user.masquerade),
    isCostHidden: ({ user }) => !!user?.hideCosts,
    isWebUser: ({ user }) => !user.customerType?.name || user.customerType?.name === 'WEB',
    isMitchell1User: ({ user }) => user.customerType?.name === 'MITCHELL',
    isROWriterUser: ({ user }) => user.customerType?.name === 'ROWRITER',
    isSmartEquipUser: ({ user }) => user.customerType?.name === 'SMARTEQUIP',
    isCXMLUser: ({ user }) => cxmlCustomerTypes.has(user.customerType?.name),
    isSMSUser: ({ user }) => user.customerTypeSms,
    isDemo: ({ currentShop }) => Boolean(currentShop?.company?.demo),
    userHasAnyRole:
        ({ user }) =>
        (...roles) =>
            Boolean(user?.group) && roles.some((role) => user?.group === role),
    userCanAccessSupport: (state, { userHasAnyRole }) => userHasAnyRole(Role.SUPERUSER, Role.SYSADMIN, Role.INSTALLER_SUPPORT, Role.MARKETING),
    userCanEditCompany: (state, { isSMSUser, userHasAnyRole }) =>
        !isSMSUser && userHasAnyRole(Role.SUPERUSER, Role.INSTALLER_SUPPORT, Role.COMPANY_MANAGER, Role.DEMO_USER),
    userCanEditShop: (state, { isSMSUser, userCanEditCompany, userHasAnyRole }) =>
        !isSMSUser && (userCanEditCompany || userHasAnyRole(Role.SYSADMIN, Role.SHOP_MANAGER, Role.MARKETING)),
    userCanViewStatements: (state, { userHasAnyRole }) =>
        userHasAnyRole(
            Role.SUPERUSER,
            Role.SYSADMIN,
            Role.INSTALLER_SUPPORT,
            Role.COMPANY_MANAGER,
            Role.SHOP_MANAGER,
            Role.MARKETING,
            Role.OREILLY_EMPLOYEE,
            Role.DEMO_USER,
            Role.SMS
        ),
    userCanViewLaborClaims: (state, { userHasAnyRole }) =>
        userHasAnyRole(
            Role.SUPERUSER,
            Role.SYSADMIN,
            Role.INSTALLER_SUPPORT,
            Role.COMPANY_MANAGER,
            Role.SHOP_MANAGER,
            Role.MARKETING,
            Role.OREILLY_EMPLOYEE,
            Role.DEMO_USER,
            Role.SMS
        ),
    userCanViewActivityLog: (state, { isSMSUser, userCanEditCompany, userHasAnyRole }) =>
        !isSMSUser && (userCanEditCompany || userHasAnyRole(Role.SYSADMIN, Role.SHOP_MANAGER)),
    featuresByKey: ({ features }) =>
        features.reduce((featuresByKey, feature) => {
            featuresByKey[feature.descriptor] = feature;
            return featuresByKey;
        }, {}),
    isFeatureDisabled:
        ({ features }, { featuresByKey }) =>
        (descriptor) =>
            !features.length || featuresByKey[descriptor]?.enabled === false,
    userDefaultLandingPage: (
        { isSPA, user },
        { isAuthorizedUser, userHasAnyRole, isFeatureDisabled, isMitchell1User, isROWriterUser, isSmartEquipUser, isCXMLUser }
    ) => {
        if (!isAuthorizedUser) {
            return !isSPA ? '/login.html' : '/login';
        }
        if (userHasAnyRole(Role.INSTALLER_SUPPORT, Role.MARKETING)) {
            return !isSPA ? '/admin/support.html' : '/support';
        }
        if (user.carCustomer && !isFeatureDisabled('car_redirect_enabled')) {
            return !isSPA ? '/carLanding.html' : '/car-admin';
        }
        if (isMitchell1User || isROWriterUser || isSmartEquipUser || isCXMLUser) {
            return !isSPA ? '/catalog/browse.html' : '/catalog/browse';
        }
        return '/';
    },
};

const store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: {
        vehicleSelector,
        miniQuote,
        stockOrderQuickAdd,
        opp,
        kits,
        partSelection,
    },
});

// If the localStorage copy of our store state is updated in another tab or window, keep the current state here in sync.
const mutationsByStorageKey = new Map([
    [StorageKey.USER, 'setUser'],
    [StorageKey.AUTH, 'setAuthTokens'],
    [StorageKey.MASQUERADE_SOURCE, 'setMasqueradeSource'],
    [StorageKey.CURRENT_VEHICLE, 'vehicleSelector/setCurrentVehicle'],
]);
window.addEventListener('storage', ({ key }) => {
    if (!mutationsByStorageKey.has(key)) return;
    store.commit(mutationsByStorageKey.get(key), getStorageItem(key));
    if (key === StorageKey.AUTH) {
        store.dispatch('startRefreshTimer');
    }
});

export default store;
