<template>
    <component :is="`icon-${name}`" :class="['icon', name]" />
</template>

<script>
import IconAndroid from 'fco/assets/icons/android.svg';
import IconAngleDoubleDown from 'fco/assets/icons/angle-double-down.svg';
import IconAngleDoubleLeft from 'fco/assets/icons/angle-double-left.svg';
import IconAngleDoubleRight from 'fco/assets/icons/angle-double-right.svg';
import IconAngleDoubleUp from 'fco/assets/icons/angle-double-up.svg';
import IconAngleDown from 'fco/assets/icons/angle-down.svg';
import IconAngleLeft from 'fco/assets/icons/angle-left.svg';
import IconAngleRight from 'fco/assets/icons/angle-right.svg';
import IconAngleUp from 'fco/assets/icons/angle-up.svg';
import IconApple from 'fco/assets/icons/apple.svg';
import IconArchive from 'fco/assets/icons/archive.svg';
import IconArrowCircleLeft from 'fco/assets/icons/arrow-circle-left.svg';
import IconArrowCircleUp from 'fco/assets/icons/arrow-circle-up.svg';
import IconArrowsH from 'fco/assets/icons/arrows-h.svg';
import IconArrowUp from 'fco/assets/icons/arrow-up.svg';
import IconBan from 'fco/assets/icons/ban.svg';
import IconBars from 'fco/assets/icons/bars.svg';
import IconBook from 'fco/assets/icons/book.svg';
import IconCalculator from 'fco/assets/icons/calculator.svg';
import IconCalendar from 'fco/assets/icons/calendar.svg';
import IconCalendarO from 'fco/assets/icons/calendar-o.svg';
import IconCar from 'fco/assets/icons/car.svg';
import IconCaretDown from 'fco/assets/icons/caret-down.svg';
import IconCaretLeft from 'fco/assets/icons/caret-left.svg';
import IconCaretRight from 'fco/assets/icons/caret-right.svg';
import IconCaretUp from 'fco/assets/icons/caret-up.svg';
import IconCartPlus from 'fco/assets/icons/cart-plus.svg';
import IconCertificate from 'fco/assets/icons/certificate.svg';
import IconCheck from 'fco/assets/icons/check.svg';
import IconCheckCircle from 'fco/assets/icons/check-circle.svg';
import IconCheckCircleO from 'fco/assets/icons/check-circle-o.svg';
import IconCheckSquare from 'fco/assets/icons/check-square.svg';
import IconCheckSquareO from 'fco/assets/icons/check-square-o.svg';
import IconChevronCircleDown from 'fco/assets/icons/chevron-circle-down.svg';
import IconChevronDown from 'fco/assets/icons/chevron-down.svg';
import IconChevronLeft from 'fco/assets/icons/chevron-left.svg';
import IconChevronRight from 'fco/assets/icons/chevron-right.svg';
import IconChevronUp from 'fco/assets/icons/chevron-up.svg';
import IconClipboard from 'fco/assets/icons/clipboard.svg';
import IconClockO from 'fco/assets/icons/clock-o.svg';
import IconCog from 'fco/assets/icons/cog.svg';
import IconComment from 'fco/assets/icons/comment.svg';
import IconCommentsO from 'fco/assets/icons/comments-o.svg';
import IconDownload from 'fco/assets/icons/download.svg';
import IconEllipsisV from 'fco/assets/icons/ellipsis-v.svg';
import IconEnvelope from 'fco/assets/icons/envelope.svg';
import IconExclamation from 'fco/assets/icons/exclamation.svg';
import IconExclamationCircle from 'fco/assets/icons/exclamation-circle.svg';
import IconExclamationTriangle from 'fco/assets/icons/exclamation-triangle.svg';
import IconExternalLink from 'fco/assets/icons/external-link.svg';
import IconEye from 'fco/assets/icons/eye.svg';
import IconFax from 'fco/assets/icons/fax.svg';
import IconFileO from 'fco/assets/icons/file-o.svg';
import IconFilePdfO from 'fco/assets/icons/file-pdf-o.svg';
import IconFileText from 'fco/assets/icons/file-text.svg';
import IconFileTextO from 'fco/assets/icons/file-text-o.svg';
import IconFilter from 'fco/assets/icons/filter.svg';
import IconFloppyO from 'fco/assets/icons/floppy-o.svg';
import IconGift from 'fco/assets/icons/gift.svg';
import IconHandORight from 'fco/assets/icons/hand-o-right.svg';
import IconHeart from 'fco/assets/icons/heart.svg';
import IconHeartO from 'fco/assets/icons/heart-o.svg';
import IconHome from 'fco/assets/icons/home.svg';
import IconInfo from 'fco/assets/icons/info.svg';
import IconInfoCircle from 'fco/assets/icons/info-circle.svg';
import IconLeaf from 'fco/assets/icons/leaf.svg';
import IconList from 'fco/assets/icons/list.svg';
import IconListUl from 'fco/assets/icons/list-ul.svg';
import IconLock from 'fco/assets/icons/lock.svg';
import IconMapMarker from 'fco/assets/icons/map-marker.svg';
import IconMinus from 'fco/assets/icons/minus.svg';
import IconMinusSquare from 'fco/assets/icons/minus-square.svg';
import IconMobile from 'fco/assets/icons/mobile.svg';
import IconPause from 'fco/assets/icons/pause.svg';
import IconPencil from 'fco/assets/icons/pencil.svg';
import IconPencilSquareO from 'fco/assets/icons/pencil-square-o.svg';
import IconPhone from 'fco/assets/icons/phone.svg';
import IconPlay from 'fco/assets/icons/play.svg';
import IconPlayCircle from 'fco/assets/icons/play-circle.svg';
import IconPlayCircleO from 'fco/assets/icons/play-circle-o.svg';
import IconPlus from 'fco/assets/icons/plus.svg';
import IconPlusSquare from 'fco/assets/icons/plus-square.svg';
import IconPowerOff from 'fco/assets/icons/power-off.svg';
import IconPrint from 'fco/assets/icons/print.svg';
import IconQuestion from 'fco/assets/icons/question.svg';
import IconQuoteLeft from 'fco/assets/icons/quote-left.svg';
import IconQuoteRight from 'fco/assets/icons/quote-right.svg';
import IconRefresh from 'fco/assets/icons/refresh.svg';
import IconSave from 'fco/assets/icons/save.svg';
import IconSearch from 'fco/assets/icons/search.svg';
import IconSearchPlus from 'fco/assets/icons/search-plus.svg';
import IconShamrock from 'fco/assets/icons/shamrock.svg';
import IconShare from 'fco/assets/icons/share.svg';
import IconShareAndroid from 'fco/assets/icons/share-alt.svg';
import IconSignIn from 'fco/assets/icons/sign-in.svg';
import IconSignOut from 'fco/assets/icons/sign-out.svg';
import IconSort from 'fco/assets/icons/sort.svg';
import IconSortAsc from 'fco/assets/icons/sort-asc.svg';
import IconSortDesc from 'fco/assets/icons/sort-desc.svg';
import IconSpinner from 'fco/assets/icons/spinner.svg';
import IconSquareO from 'fco/assets/icons/square-o.svg';
import IconTachometer from 'fco/assets/icons/tachometer.svg';
import IconTags from 'fco/assets/icons/tags.svg';
import IconTimes from 'fco/assets/icons/times.svg';
import IconTrash from 'fco/assets/icons/trash.svg';
import IconTruck from 'fco/assets/icons/truck.svg';
import IconUsd from 'fco/assets/icons/usd.svg';
import IconUser from 'fco/assets/icons/user.svg';
import IconUserPlus from 'fco/assets/icons/user-plus.svg';
import IconView360 from 'fco/assets/icons/view-360.svg';
import IconWindows from 'fco/assets/icons/windows.svg';
import IconWrench from 'fco/assets/icons/wrench.svg';
import IconYoutubePlay from 'fco/assets/icons/youtube-play.svg';
import IconShareApple from '../../assets/icon-share-apple.svg';

export default {
    name: 'Icon',
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    components: {
        IconAndroid,
        IconAngleDoubleDown,
        IconAngleDoubleLeft,
        IconAngleDoubleRight,
        IconAngleDoubleUp,
        IconAngleDown,
        IconAngleLeft,
        IconAngleRight,
        IconAngleUp,
        IconApple,
        IconArchive,
        IconArrowCircleLeft,
        IconArrowCircleUp,
        IconArrowsH,
        IconArrowUp,
        IconBan,
        IconBars,
        IconBook,
        IconCalculator,
        IconCalendar,
        IconCalendarO,
        IconCar,
        IconCaretDown,
        IconCaretLeft,
        IconCaretRight,
        IconCaretUp,
        IconCartPlus,
        IconCertificate,
        IconCheck,
        IconCheckCircle,
        IconCheckCircleO,
        IconCheckSquare,
        IconCheckSquareO,
        IconChevronCircleDown,
        IconChevronDown,
        IconChevronLeft,
        IconChevronRight,
        IconChevronUp,
        IconClipboard,
        IconClockO,
        IconCog,
        IconComment,
        IconCommentsO,
        IconDownload,
        IconEllipsisV,
        IconEnvelope,
        IconExclamation,
        IconExclamationCircle,
        IconExclamationTriangle,
        IconExternalLink,
        IconEye,
        IconFax,
        IconFileO,
        IconFilePdfO,
        IconFileText,
        IconFileTextO,
        IconFilter,
        IconFloppyO,
        IconGift,
        IconHandORight,
        IconHeart,
        IconHeartO,
        IconHome,
        IconInfo,
        IconInfoCircle,
        IconLeaf,
        IconList,
        IconListUl,
        IconLock,
        IconMapMarker,
        IconMinus,
        IconMinusSquare,
        IconMobile,
        IconPause,
        IconPencil,
        IconPencilSquareO,
        IconPhone,
        IconPlay,
        IconPlayCircle,
        IconPlayCircleO,
        IconPlus,
        IconPlusSquare,
        IconPowerOff,
        IconPrint,
        IconQuestion,
        IconQuoteLeft,
        IconQuoteRight,
        IconRefresh,
        IconSave,
        IconSearch,
        IconSearchPlus,
        IconShamrock,
        IconShare,
        IconShareAndroid,
        IconShareApple,
        IconSignIn,
        IconSignOut,
        IconSort,
        IconSortAsc,
        IconSortDesc,
        IconSpinner,
        IconSquareO,
        IconTachometer,
        IconTags,
        IconTimes,
        IconTrash,
        IconTruck,
        IconUsd,
        IconUser,
        IconUserPlus,
        IconView360,
        IconWindows,
        IconWrench,
        IconYoutubePlay,
    },
};
</script>

<style lang="scss" scoped>
@import '~scssVariables/config';
@import '~scssVariables/mixins';

.icon {
    @include critical;

    width: 1em;
    height: 1em;
    vertical-align: middle;
    top: auto;
    position: relative;
    margin: 0;
    pointer-events: none;
    fill: currentColor;
}
</style>
