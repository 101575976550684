import axios from 'axios';
import { requestSettled } from '@/common/store/request-status';
import store, { state } from './store/store';

axios.interceptors.request.use((config) => {
    if (!state.i18n?.locale) return config;
    return {
        ...config,
        headers: {
            ...config.headers,
            'Accept-Language': state.i18n?.locale,
        },
    };
});

axios.interceptors.request.use(async (config) => {
    if (state.isSPA && !state.auth) return config;

    if (!state.user?.userPreference?.selectedShopId && !config.url.includes('/session/user')) {
        await requestSettled(() => state.requests.getUser);
    }
    const selectedShopId = state.user?.userPreference?.selectedShopId;
    if (!selectedShopId) return config;
    return {
        ...config,
        headers: {
            ...config.headers,
            'x-sticky': selectedShopId,
        },
    };
});
axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const isExpiredAuthToken = state.isSPA && state.auth && error.response?.status === 401;
        let responseError = error;

        if (isExpiredAuthToken) {
            // Avoid an infinite loop. Did we already retry the reqeuest?
            // Make sure this error isn't the result of an attempt to retry the request.
            const isRefreshTokenRequest = error.config?.url.includes('/auth/rest/refresh');
            const isRetryRequest = !!error.config?.isRetry;
            if (!isRefreshTokenRequest && !isRetryRequest) {
                try {
                    await store.dispatch('refreshAuth');

                    // With our refreshed access token in hand, let's try the request again.
                    return axios({
                        ...error.config,
                        headers: {
                            ...error.config?.headers,
                            Authorization: axios.defaults.headers.common.Authorization,
                        },
                        isRetry: true,
                    });
                } catch (retryError) {
                    responseError = retryError;
                }
            }

            // A failed request here likely means our tokens are expired, and the user is logged out.
            // Regardless of the cause, something very bad went wrong and we can't authenticate our user.
            // Time to clear out the session.
            store.dispatch('logout');
        }

        return Promise.reject(responseError);
    }
);

axios.defaults.baseURL = __FCO_API_URL__;
