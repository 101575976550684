<template>
    <div>
        <section class="login-area black-bg p-0 py-4 py-sm-2">
            <div class="row content">
                <div class="login-section col-lg-6 col-md-12 px-0 py-3">
                    <h2 class="sr-only">{{ fcoM['common.logIn'] }}</h2>
                    <ValidationObserver tag="div">
                        <template #default="{ validate }">
                            <form @submit.prevent="validate().then(handleSubmit)">
                                <div class="login-form">
                                    <div v-if="lockWarning">
                                        <div class="alert alert-danger p-2 d-flex">
                                            <div>
                                                <Icon name="lock" class="medium red lock" />
                                            </div>
                                            <div class="flex-fill">
                                                {{ fcoM['rs.home.lockWarning'] }}
                                                <FcoLink
                                                    class="gtm-login-forgot-password"
                                                    to="/forgot-password"
                                                    :href="fcoUrl('/forgotpassword.html')"
                                                    >{{ fcoM['rs.home.forgotUserPass'] }}</FcoLink
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="loginErrorMessage" class="alert alert-danger p-2 d-flex">
                                        <div>
                                            <Icon name="exclamation" class="medium red exclamation" />
                                        </div>
                                        <div class="flex-fill">
                                            {{ loginErrorMessage }}
                                            <FcoLink
                                                v-if="showForgotPassword"
                                                class="gtm-login-forgot-password"
                                                to="/forgot-password"
                                                :href="fcoUrl('/forgotpassword.html')"
                                                >{{ fcoM['rs.home.forgotUserPass'] }}</FcoLink
                                            >
                                        </div>
                                    </div>
                                    <div class="user-name select-box mb-2">
                                        <label for="userName" class="sr-only">{{ fcoM['common.userName'] }}</label>
                                        <ValidationProvider
                                            :rules="{
                                                required: true,
                                                basicChars: true,
                                                regex: /^[A-Za-z0-9]{3,}(?:@oreillyauto\.(com|mx))?$/,
                                                min: 3,
                                                max: 50,
                                            }"
                                            :name="fcoM['common.userName']"
                                            tag="div"
                                            v-slot="{ errors, classes }"
                                        >
                                            <Icon name="user" class="input-icon medium med-gray user" />
                                            <input
                                                type="text"
                                                id="loginName"
                                                :class="[classes, 'qaa-js-login-user', 'highlight-on-focus']"
                                                v-model="loginForm.loginName"
                                                :placeholder="fcoM['common.userName']"
                                            />
                                            <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="password select-box mb-2">
                                        <label for="password" class="sr-only">{{ fcoM['common.password'] }}</label>
                                        <ValidationProvider
                                            :rules="{
                                                required: true,
                                                min: 3,
                                            }"
                                            :name="fcoM['common.password']"
                                            tag="div"
                                            v-slot="{ errors, classes }"
                                        >
                                            <Icon name="lock" class="input-icon medium med-gray lock" />
                                            <input
                                                type="password"
                                                id="password"
                                                :class="[classes, 'qaa-js-login-password', 'highlight-on-focus']"
                                                v-model="loginForm.password"
                                                :placeholder="fcoM['common.password']"
                                            />
                                            <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="remember-me d-block d-sm-flex">
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                id="rememberMe"
                                                type="checkbox"
                                                class="ignore custom-control-input"
                                                v-model="loginForm.rememberMe"
                                            />
                                            <label for="rememberMe" class="d-inline-block white custom-control-label">
                                                {{ fcoM['rs.home.staySignedIn'] }}
                                            </label>
                                        </div>
                                        <FcoLink
                                            to="/forgot-password"
                                            :href="fcoUrl('/forgotpassword.html')"
                                            class="ml-auto gtm-login-forgot-password forgotUsernamePasswordLink"
                                        >
                                            {{ fcoM['rs.home.forgotUserPass'] }}
                                        </FcoLink>
                                    </div>
                                    <div class="submit d-block pt-3">
                                        <button type="submit" class="button py-2 px-3 mr-0 mr-sm-2 mr-lg-1 gtm-login qaa-gtm-login">
                                            {{ fcoM['common.logIn'] }}
                                            <Icon v-if="!isLoading" id="gtm-svg-login" name="chevron-right" class="medium chevron-right" />
                                            <Icon v-if="isLoading" name="refresh" class="medium refresh" />
                                        </button>
                                        <div id="chatHelpHomePageLink" class="chat-help"></div>
                                    </div>
                                </div>
                            </form>
                        </template>
                    </ValidationObserver>
                </div>
                <div class="col-lg-6 col-md-12 login-section">
                    <h2 class="sr-only">{{ fcoM['common.requestAccess'] }}</h2>
                    <div class="request-access mt-lg-3 p-4 p-md-0 d-md-flex d-lg-block">
                        <FcoLink
                            class="button py-2 px-3 mr-0 mr-sm-2 mr-sm-0 text-center qaa-request-access d-block d-lg-inline-block"
                            to="/account-request"
                            :href="fcoUrl('/accountrequest/request.html')"
                        >
                            {{ fcoM['common.requestAccess'] }}
                            <Icon name="chevron-right" class="medium chevron-right" />
                        </FcoLink>
                        <p class="mb-0 ml-0 ml-sm-2 ml-lg-0" v-html="fcoM['rs.home.requestAssist']" />
                    </div>
                </div>
            </div>
        </section>
        <section v-if="!$fcoMq.isSmDown" class="marketing-area white-bg d-none d-sm-block pb-4">
            <div class="content mb-0 d-none d-md-block">
                <div class="row">
                    <div class="col-9">
                        <h2 class="sr-only">{{ fcoM['rs.home.carouselHeader'] }}</h2>
                        <BannerZone name="login-slider" carousel placeholder />
                    </div>
                    <div class="col-3 d-flex flex-wrap">
                        <BannerZone name="login-side-banner-top" class="gtm-login-credit" placeholder />
                        <BannerZone name="login-side-banner-bottom" class="gtm-login-direct-capital" placeholder />
                    </div>
                </div>
            </div>
        </section>

        <MarketingBoxes />
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';
import MarketingBoxes from './MarketingBoxes.vue';
import Icon from '../../../common/components/Icon.vue';
import BannerZone from '../../../common/components/cmsContent/BannerZone.vue';
import { isRequestError, requestSettled } from '../../../common/store/request-status';
import FcoLink from '../../../common/components/FcoLink.vue';
import loginErrorMixin from '../../../common/mixins/loginErrorMixin';
import { ErrorKeys } from '../../../common/constants/login';

const ForbiddenReasons = {
    SECURITY_CHANGE_REQUIRED: 'SECURITY_CHANGE_REQUIRED',
    TOS_REQUIRED: 'TOS_REQUIRED',
};

export default {
    name: 'Login',
    data() {
        return {
            loginForm: {
                loginName: '',
                password: '',
                rememberMe: false,
            },
            isLoading: false,
        };
    },
    components: {
        Icon,
        MarketingBoxes,
        BannerZone,
        FcoLink,
    },
    mixins: [loginErrorMixin],
    computed: {
        ...mapState(['requests', 'isSPA', 'csrfObject']),
        ...mapGetters(['userDefaultLandingPage']),
        lockWarning() {
            const lockWarning = !this.isSPA ? new URLSearchParams(window.location.search).get('lockWarning') : this.$route.query.lockWarning;
            return lockWarning === 'true';
        },
        showForgotPassword() {
            return this.loginErrorKey === ErrorKeys.LOGIN_AUTHENTICATION_FAILED;
        },
    },
    methods: {
        handleLoginError(error) {
            const responseStatus = error.response?.status;
            const errorId = error.response?.data?.errorId;
            const isForbidden = responseStatus === 403;
            const isTermsAndConditionsRequired = isForbidden && errorId === ForbiddenReasons.TOS_REQUIRED;
            const isPasswordExpired = isForbidden && errorId === ForbiddenReasons.SECURITY_CHANGE_REQUIRED;

            if (isTermsAndConditionsRequired || isPasswordExpired) {
                if (isTermsAndConditionsRequired) this.$router.push('/accept-terms');
                else this.$router.push('/security-change');
                return;
            }

            const errorMessage = this.loginErrorMessagesByKey[errorId] || this.fcoM['rs.loginError.errorOccurredDuringLogin'];
            this.$fcoToast.error(errorMessage);
        },
        async handleSubmit() {
            this.isLoading = true;
            if (this.isSPA) {
                try {
                    await this.$store.dispatch('login', this.loginForm);
                    this.$store.dispatch('getUser');
                    await requestSettled(() => this.requests.getUser);
                    if (isRequestError(this.requests.getUser)) {
                        this.handleLoginError(this.requests.getUser.error);
                    } else {
                        this.$store.dispatch('refetchUserCriticalData');
                    }
                } catch (error) {
                    this.handleLoginError(error);
                }
            } else {
                const data = new URLSearchParams();
                Object.entries(this.loginForm).forEach(([key, value]) => {
                    data.append(key, value);
                });
                data.append('_csrf', this.csrfObject.token);
                await axios
                    .post(this.fcoUrl('/login.html'), data)
                    .then((response) => {
                        window.location.href = response.request.responseURL;
                    })
                    .catch(() => {
                        this.$fcoToast.error(this.fcoM['rs.loginError.errorOccurredDuringLogin']);
                    });
            }
            this.isLoading = false;
        },
    },
    mounted() {
        // clear out current stores for google analytics tracking
        const loggedStoreKeys = Object.keys(window.localStorage).filter((key) => key.includes('GTMLoggedCurrentStore'));

        loggedStoreKeys.forEach((key) => {
            window.localStorage.removeItem(key);
        });
    },
};
</script>

<style scoped lang="scss">
@import '~scssVariables/config';
@import '~scssVariables/mixins';

.login-area {
    .user-name,
    .password {
        input {
            padding-left: 35px;
        }
    }

    .chat-help {
        color: $white;
        cursor: pointer;

        .icon {
            @include respond(sm) {
                fill: $white;
            }
        }

        svg {
            bottom: 2px;
            width: 18px;
            height: 18px;
        }

        @include respond-up-to(sm) {
            color: $black;
        }
    }

    &.black-bg {
        @include respond-up-to(sm) {
            background-color: $white;
        }
    }

    .white-bg {
        background-color: $white;
    }

    .login-section {
        @include respond-up-to(md) {
            width: 100%;
            padding: 0;
        }

        .login-form,
        div.request-access {
            width: 80%;
            margin: 0 auto;

            @include respond-up-to(md) {
                width: 100%;
            }
        }

        .login-form {
            .remember-me {
                label {
                    color: $white;
                    @include respond-up-to(sm) {
                        color: $black;
                        font-weight: normal;
                    }
                }
            }
        }

        div.request-access {
            @include border-radius-fco;

            p {
                color: $white;
            }

            @include respond-up-to(sm) {
                border: $border-gray;
                background-color: lighten($gray-300, 10%);

                p {
                    color: $black;
                }
            }
        }

        .input-icon {
            position: absolute;
            margin-left: 10px;

            &.user,
            &.lock {
                top: 7px;

                @media (max-width: 1280px) and (min-width: 1025px) {
                    top: 6px;
                }

                @media (max-width: 1024px) and (min-width: 769px) {
                    top: 7px;
                }

                @include respond-up-to(sm) {
                    top: 10px;
                }
            }

            &.lock {
                @include size-21;

                @media (max-width: 1024px) and (min-width: 769px) {
                    @include size-18;
                }
            }
            &.user {
                @include size-18;
            }
        }
    }

    /* tablet layout */
    @include respond(sm, md) {
        .submit {
            border-bottom: solid 1px white;
            padding-bottom: $padding;
        }

        .submit,
        .request-access {
            .button,
            p {
                flex-basis: 50%;
            }
        }
    }
}

.forgotUsernamePasswordLink {
    color: $white;

    @include respond-up-to(sm) {
        text-decoration: underline;
        color: $green;
        font-weight: normal;
        margin-bottom: 0;

        &:hover {
            color: $link-hover;
            text-decoration: underline;
        }
    }
}
</style>
