<template>
    <ValidationObserver v-slot="{ validate }" slim>
        <FcoModal size="xl" header-class="title-bar-oswald font-weight-normal" :title-text="fcoM['rs.quote.Modal.vi.header']" @close="$emit('close')">
            <form class="position-relative" @submit.prevent="validate().then(saveVehicleInfo)">
                <div class="row">
                    <div class="col-lg">
                        <FcoTitleBar bg-color="gray" class="mb-2">
                            {{ fcoM['common.customerInformation'] }}
                            <FcoLink
                                v-if="vehicleInfo.selectedShopCustomerId"
                                :to="`/customers/details/${vehicleInfo.selectedShopCustomerId}`"
                                :href="fcoUrl(`/customers/#/details/${vehicleInfo.selectedShopCustomerId}`)"
                                class="ml-3 text-xs"
                                >{{ fcoM['rs.quote.vm.viewCustomer'] }}</FcoLink
                            >
                        </FcoTitleBar>

                        <div class="row" v-if="!vehicleInfo.selectedShopCustomerId">
                            <div class="col-sm-6 form-group">
                                <label>{{ fcoM['rs.quote.vm.selectCustomer'] }}</label>
                                <select class="custom-select" v-model="vehicleInfo.selectedShopCustomerId">
                                    <option :value="0">{{ fcoM['common.newCustomer'] }}</option>
                                    <option v-for="shopCustomer in shopCustomers" :key="shopCustomer.id" :value="shopCustomer.id">
                                        {{ getCustomerName(shopCustomer) }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm form-group">
                                <ValidationProvider
                                    tag="div"
                                    :name="fcoM['common.firstName']"
                                    :rules="{
                                        required: isCustomerNameRequired,
                                        max: 50,
                                        basicChars: true,
                                    }"
                                    v-slot="{ errors, classes }"
                                >
                                    <label>{{ fcoM['common.firstName'] }}</label>
                                    <input type="text" :placeholder="fcoM['common.firstName']" v-model="vehicleInfo.firstName" :class="classes" />
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-sm form-group">
                                <ValidationProvider
                                    tag="div"
                                    :name="fcoM['common.lastName']"
                                    :rules="{
                                        required: isCustomerNameRequired,
                                        max: 50,
                                        basicChars: true,
                                    }"
                                    v-slot="{ errors, classes }"
                                >
                                    <label>{{ fcoM['common.lastName'] }}</label>
                                    <input type="text" :placeholder="fcoM['common.lastName']" v-model="vehicleInfo.lastName" :class="classes" />
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm form-group">
                                <ValidationProvider
                                    tag="div"
                                    :name="fcoM['common.address.street1']"
                                    :rules="{
                                        required: hasCustomerAddressInfo,
                                        max: 80,
                                        basicChars: true,
                                    }"
                                    v-slot="{ errors, classes }"
                                >
                                    <label>{{ fcoM['common.address.street1'] }}</label>
                                    <input type="text" v-model="vehicleInfo.street1" :placeholder="fcoM['common.address.street1']" :class="classes" />
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-sm form-group">
                                <ValidationProvider
                                    tag="div"
                                    :name="fcoM['common.address.street2']"
                                    :rules="{
                                        max: 80,
                                        basicChars: true,
                                    }"
                                    v-slot="{ errors, classes }"
                                >
                                    <label>{{ fcoM['common.address.street2'] }}</label>
                                    <input type="text" v-model="vehicleInfo.street2" :placeholder="fcoM['common.address.street2']" :class="classes" />
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm form-group">
                                <ValidationProvider
                                    tag="div"
                                    :name="fcoM['common.address.city']"
                                    :rules="{
                                        required: hasCustomerAddressInfo,
                                        max: 40,
                                        basicChars: true,
                                    }"
                                    v-slot="{ errors, classes }"
                                >
                                    <label>{{ fcoM['common.address.city'] }}</label>
                                    <input type="text" v-model="vehicleInfo.city" :placeholder="fcoM['common.address.city']" :class="classes" />
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-sm form-group">
                                <ValidationProvider
                                    tag="div"
                                    :name="fcoM['common.address.state']"
                                    :rules="{ required: hasCustomerAddressInfo }"
                                    v-slot="{ errors, classes }"
                                >
                                    <label>{{ fcoM['common.address.state'] }}</label>
                                    <select :class="['custom-select', classes]" v-model="vehicleInfo.state">
                                        <option v-for="state in usStates" :key="`state${state.abbr}`" :value="state.abbr">{{ state.name }}</option>
                                    </select>
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-sm">
                                <ValidationProvider
                                    tag="div"
                                    :name="fcoM['common.address.zip']"
                                    :rules="{
                                        required: hasCustomerAddressInfo,
                                        max: 10,
                                        zipCode: true,
                                    }"
                                    v-slot="{ errors, classes }"
                                >
                                    <label>{{ fcoM['common.address.zip'] }}</label>
                                    <input type="text" v-model="vehicleInfo.zip" :placeholder="fcoM['common.address.zip']" :class="classes" />
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm form-group">
                                <ValidationProvider
                                    tag="div"
                                    :name="fcoM['rs.quote.vm.phone']"
                                    :rules="{
                                        usPhone: true,
                                        max: 20,
                                    }"
                                    v-slot="{ errors, classes }"
                                >
                                    <label>{{ fcoM['rs.quote.vm.phone'] }}</label>
                                    <input type="text" v-model="vehicleInfo.phone" :placeholder="fcoM['rs.quote.vm.phone']" :class="classes" />
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-sm form-group">
                                <ValidationProvider
                                    tag="div"
                                    :name="fcoM['common.email']"
                                    :rules="{
                                        email: true,
                                        max: 80,
                                    }"
                                    v-slot="{ errors, classes }"
                                >
                                    <label>{{ fcoM['common.email'] }}</label>
                                    <input type="text" v-model="vehicleInfo.email" :placeholder="fcoM['common.email']" :class="classes" />
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg">
                        <FcoTitleBar bg-color="gray" class="mb-2">{{ vehicleInfo.shopVehicleDescriptor }}</FcoTitleBar>

                        <div class="form-group">
                            <ValidationProvider
                                tag="div"
                                :name="fcoM['common.description']"
                                :rules="{
                                    basicChars: true,
                                    max: 255,
                                }"
                                v-slot="{ errors, classes }"
                            >
                                <label>{{ fcoM['common.description'] }}</label>
                                <input
                                    type="text"
                                    v-model="vehicleInfo.vehicleDescription"
                                    :placeholder="fcoM['common.description']"
                                    :class="classes"
                                />
                                <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="form-group">
                            <label>{{ fcoM['common.vehicleAttributes'] }}</label>
                            <div v-if="vehicleAttributes.length === 0">-</div>
                            <div v-else>
                                <VehicleAttribute
                                    v-for="attribute in vehicleAttributes.slice(0, 2)"
                                    :key="attribute.attributeId"
                                    :attribute="attribute"
                                    @remove="removeAttribute(attribute.attributeId)"
                                />
                                <fco-collapse :show="showAllAttributes">
                                    <VehicleAttribute
                                        v-for="attribute in vehicleAttributes.slice(2)"
                                        :key="attribute.attributeId"
                                        :attribute="attribute"
                                        @remove="removeAttribute(attribute.attributeId)"
                                    />
                                </fco-collapse>
                            </div>
                            <div v-if="vehicleAttributes.length > 2" class="mt-2">
                                <a href="javascript:void(0)" @click="showAllAttributes = !showAllAttributes">
                                    {{ showAllAttributes ? fcoM['common.hide'] : fcoM['common.link.showAll'] }}
                                </a>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm form-group">
                                <ValidationProvider tag="div" :name="fcoM['common.odometer']" rules="numberWithCommas" v-slot="{ errors, classes }">
                                    <label>{{ fcoM['common.odometer'] }}</label>
                                    <input
                                        type="number"
                                        :value="vehicleInfo.vehicleMileage"
                                        @input="handleMileageInput"
                                        :placeholder="fcoM['common.odometer']"
                                        :class="classes"
                                    />
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-sm form-group">
                                <ValidationProvider
                                    tag="div"
                                    :name="fcoM['common.licensePlate']"
                                    :rules="{
                                        basicChars: true,
                                        max: 10,
                                    }"
                                    v-slot="{ errors, classes }"
                                >
                                    <label>{{ fcoM['common.licensePlate'] }}</label>
                                    <input
                                        type="text"
                                        v-model="vehicleInfo.vehicleLicensePlate"
                                        :placeholder="fcoM['common.licensePlate']"
                                        :class="classes"
                                    />
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm" v-if="currentShopPreference.vehicleFleetIdVisible">
                                <ValidationProvider
                                    tag="div"
                                    :name="fcoM['common.fleetId']"
                                    :rules="{
                                        basicChars: true,
                                        max: 10,
                                    }"
                                    v-slot="{ errors, classes }"
                                >
                                    <label>{{ fcoM['common.fleetId'] }}</label>
                                    <input type="text" v-model="vehicleInfo.vehicleFleetId" :placeholder="fcoM['common.fleetId']" :class="classes" />
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>

                            <div class="col-sm form-group" v-if="currentShopPreference.garageBayIdVisible">
                                <ValidationProvider
                                    tag="div"
                                    :name="fcoM['common.garageBayId']"
                                    :rules="{
                                        basicChars: true,
                                        max: 4,
                                    }"
                                    v-slot="{ errors, classes }"
                                >
                                    <label>{{ fcoM['common.garageBayId'] }}</label>
                                    <input
                                        type="text"
                                        v-model="vehicleInfo.vehicleGarageBay"
                                        :placeholder="fcoM['common.garageBayId']"
                                        :class="classes"
                                    />
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm form-group">
                                <ValidationProvider
                                    tag="div"
                                    :name="fcoM['rs.quote.vm.symptoms']"
                                    :rules="{
                                        basicCharsMultiline: true,
                                        max: 1020,
                                    }"
                                    v-slot="{ errors, classes }"
                                >
                                    <label>{{ fcoM['rs.quote.vm.symptoms'] }}</label>
                                    <textarea
                                        v-model="vehicleInfo.customerComplaint"
                                        :placeholder="fcoM['rs.quote.vm.symptoms']"
                                        :class="classes"
                                    ></textarea>
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-sm form-group">
                                <ValidationProvider
                                    tag="div"
                                    :name="fcoM['common.notes']"
                                    :rules="{
                                        basicCharsMultiline: true,
                                        max: 1020,
                                    }"
                                    v-slot="{ errors, classes }"
                                >
                                    <label>{{ fcoM['common.notes'] }}</label>
                                    <textarea v-model="vehicleInfo.vehicleNotes" :placeholder="fcoM['common.notes']" :class="classes"></textarea>
                                    <span class="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>

                <button ref="submit" type="submit" class="button d-none">{{ fcoM['common.submit'] }}</button>

                <FcoLoadingOverlay v-if="isLoading" class="position-absolute" />
            </form>

            <button slot="footer" type="button" class="secondary" @click="$emit('close')">{{ fcoM['common.cancel'] }}</button>
            <button slot="footer" type="button" class="button" :disabled="!hasCustomerName" @click="validate().then(saveVehicleInfo)">
                {{ fcoM['common.submit'] }}
            </button>
        </FcoModal>
    </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import { VEHICLE_ATTRIBUTE_IDS } from '@/common/constants/vehicle';
import { omit } from 'fco/src/utils/objects';
import { FcoCollapse } from 'fco/src/vue/components/collapse';
import { FcoModal } from 'fco/src/vue/components/modal';
import { FcoTitleBar } from 'fco/src/vue/components/titlebar';
import VehicleAttribute from '@/common/components/VehicleAttribute.vue';
import { isRequestIdle } from '../store/request-status';
import FcoLink from './FcoLink.vue';

export default {
    name: 'VehicleCustomerInfo',
    components: {
        FcoCollapse,
        FcoModal,
        FcoTitleBar,
        VehicleAttribute,
        FcoLink,
    },
    props: {
        shopCustomerId: {
            type: Number,
            default: 0,
        },
        vehicleId: {
            type: Number,
            required: true,
        },
        attributes: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isLoading: false,
            shopCustomers: [],
            showAllAttributes: false,
            usStates: [],
            vehicleAttributes: [],
            vehicleInfo: this.getDefaultVehicleInfo(),
        };
    },
    computed: {
        ...mapState({
            requests: ({ requests }) => requests,
            currentShopPreference: ({ currentShop }) => currentShop.shopPreference,
        }),
        hasCustomerAddressInfo() {
            return !!(
                this.vehicleInfo.city ||
                this.vehicleInfo.state ||
                this.vehicleInfo.street1 ||
                this.vehicleInfo.street2 ||
                this.vehicleInfo.zip
            );
        },
        isCustomerNameRequired() {
            return !!(
                this.hasCustomerAddressInfo ||
                this.vehicleInfo.selectedShopCustomerId ||
                this.vehicleInfo.email ||
                this.vehicleInfo.firstName ||
                this.vehicleInfo.lastName ||
                this.vehicleInfo.phone
            );
        },
        hasCustomerName() {
            return !!(this.vehicleInfo.firstName || this.vehicleInfo.lastName);
        },
    },
    methods: {
        getCustomerName({ lastName, firstName }) {
            if (!lastName) {
                if (!firstName) {
                    return '-';
                }
                return firstName;
            }
            if (!firstName) {
                return lastName;
            }
            return `${lastName}, ${firstName}`;
        },
        getDefaultVehicleInfo() {
            return {
                city: '',
                customerComplaint: '',
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                selectedShopCustomerId: this.shopCustomerId,
                shopVehicleDescriptor: '',
                state: '',
                street1: '',
                street2: '',
                vehicleDescription: '',
                vehicleFleetId: '',
                vehicleGarageBay: '',
                vehicleLicensePlate: '',
                vehicleMileage: null,
                vehicleNotes: '',
                zip: '',
            };
        },
        async getVehicleCustomerInfo() {
            this.isLoading = true;

            try {
                const {
                    data: { notesSid: vehicleInfo, retainAttributes: vehicleAttributes, shopCustomers, usStates },
                } = await axios.get(this.fcoUrl('/worksheet/rest/addnote'), {
                    params: {
                        id: this.vehicleId,
                        shopCustId: this.vehicleInfo.selectedShopCustomerId,
                    },
                });

                this.vehicleInfo = vehicleInfo;
                this.shopCustomers = shopCustomers;
                this.usStates = usStates;

                // If the parent did not provide attributes, use what was returned
                if (!this.$options.propsData.attributes) {
                    // Map attributes into the same structure as the VS attributes
                    this.vehicleAttributes = vehicleAttributes
                        .filter(({ values }) => values && values.length > 0)
                        .map(({ description: attributeDescription, values: [value], ...attribute }) => ({
                            ...attribute,
                            ...omit(value, 'valueId'),
                            attributeDescription,
                        }));
                }
            } catch (error) {
                this.$fcoToast.error(this.fcoM['rs.quote.vm.errorLoadingNote']);
            }

            this.isLoading = false;
        },
        handleMileageInput({ target: { value: vehicleMileage } }) {
            this.vehicleInfo.vehicleMileage = vehicleMileage;

            const cleanMileage = Number(vehicleMileage.replace(/,/g, '').replace(/^0+/, ''));

            if (cleanMileage !== vehicleMileage) {
                this.vehicleInfo.vehicleMileage = cleanMileage;
            }
        },
        async removeAttribute(attributeId) {
            // If the parent provided the attributes, let it handle the removal
            if (this.$options.propsData.attributes) {
                this.$emit('remove-attribute', attributeId);
                return;
            }

            const attributeIndex = this.vehicleAttributes.findIndex((attribute) => attribute.attributeId === attributeId);

            if (attributeIndex < 0) {
                return;
            }

            try {
                const removeEngine = attributeId === VEHICLE_ATTRIBUTE_IDS.ENGINE;
                const vehicleHasSubmodelSelected = this.vehicleAttributes.some(
                    (attribute) => attribute.attributeId === VEHICLE_ATTRIBUTE_IDS.SUBMODEL
                );
                const finalIdsToRemove = [attributeId];

                // Vehicle cannot have only submodel with no engine, need to always remove submodel anytime engine is being removed as well
                if (removeEngine && vehicleHasSubmodelSelected) {
                    await axios.post(this.fcoUrl('/attribute/rest/remove'), { attributeId: VEHICLE_ATTRIBUTE_IDS.SUBMODEL });
                    finalIdsToRemove.push(VEHICLE_ATTRIBUTE_IDS.SUBMODEL);
                }
                await axios.post(this.fcoUrl('/attribute/rest/remove'), { attributeId });
                this.$store.commit('vehicleSelector/removeAttributes', finalIdsToRemove);
                this.vehicleAttributes = this.vehicleAttributes.filter((attribute) => !finalIdsToRemove.includes(attribute.attributeId));
            } catch (error) {
                this.$fcoToast.error(this.fcoM['rs.quote.vm.errorRemovingAttr']);
            }
        },
        async saveVehicleInfo() {
            this.isLoading = true;

            const encodedData = Object.entries(this.vehicleInfo)
                .map(([key, value = '']) => `${key}=${value === null ? '' : encodeURIComponent(value)}`)
                .join('&');

            try {
                let { data: selectedShopCustomerId } = await axios.post(this.fcoUrl('/worksheet/rest/addnote'), encodedData);

                if (!selectedShopCustomerId) {
                    selectedShopCustomerId = 0;
                }

                this.$emit('save', {
                    ...this.vehicleInfo,
                    selectedShopCustomerId,
                    vehicleId: this.vehicleId,
                });
            } catch (error) {
                this.$fcoToast.error(this.fcoM['rs.quote.vm.errorSaving']);
            }

            this.isLoading = false;
        },
    },
    watch: {
        shopCustomerId(shopCustomerId) {
            this.vehicleInfo.selectedShopCustomerId = shopCustomerId;
        },
        'vehicleInfo.selectedShopCustomerId'() {
            this.getVehicleCustomerInfo();
        },
        attributes: {
            immediate: true,
            handler(attributes) {
                this.vehicleAttributes = attributes;
            },
        },
    },
    async mounted() {
        if (isRequestIdle(this.requests.getCurrentShop)) this.$store.dispatch('getCurrentShop');
        this.getVehicleCustomerInfo();
    },
};
</script>
