var render = function render() {
  var _vm$currentShop, _vm$currentShop2, _vm$user
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "nav",
    {
      class: [
        "admin-bar",
        {
          "admin-bar-vertical": _vm.isVertical,
          "admin-bar-horizontal": _vm.isHorizontal,
        },
      ],
    },
    [
      _c(
        "div",
        {
          class: [
            "admin-bar-container d-flex py-1",
            {
              "align-items-center": _vm.isHorizontal,
              "flex-column": _vm.isVertical,
            },
          ],
        },
        [
          _c(
            "span",
            {
              staticClass: "admin-bar-account-name pr-3 py-1 my-1 mr-2",
            },
            [
              !(
                (_vm$currentShop = _vm.currentShop) !== null &&
                _vm$currentShop !== void 0 &&
                _vm$currentShop.id
              )
                ? _c("VueSkeletonLoader", {
                    attrs: {
                      type: "rect",
                      width: 125,
                      height: 18,
                      rounded: true,
                    },
                  })
                : [_vm._v(_vm._s(_vm.accountName))],
            ],
            2
          ),
          _vm._v(" "),
          _vm.shops.length > 1
            ? _c("FcoSearchSelect", {
                ref: "shopSearch",
                staticClass: "shop-select bg-light text-black",
                attrs: {
                  value: _vm.shopSearchVal,
                  "allow-empty": false,
                  options: _vm.shops,
                  "map-option": function (_ref) {
                    var id = _ref.id,
                      shopName = _ref.shopName
                    return {
                      value: id,
                      label: shopName,
                    }
                  },
                  "menu-container": _vm.$refs.shopSearch,
                  "input-class": ["shop-select-input"],
                  mode: "fuzzy",
                  searchKeys: ["label"],
                  placeholder: _vm.tempShopName,
                },
                on: {
                  focus: _vm.handleFocus,
                  blur: _vm.handleBlur,
                  select: _vm.handleShopSelect,
                },
              })
            : _c(
                "span",
                {
                  staticClass: "admin-bar-shop-name",
                },
                [
                  !(
                    (_vm$currentShop2 = _vm.currentShop) !== null &&
                    _vm$currentShop2 !== void 0 &&
                    _vm$currentShop2.id
                  )
                    ? _c("VueSkeletonLoader", {
                        attrs: {
                          type: "rect",
                          width: 100,
                          height: 18,
                          rounded: true,
                        },
                      })
                    : [_vm._v(_vm._s(_vm.currentShop.shopName))],
                ],
                2
              ),
          _vm._v(" "),
          _c(
            "ul",
            {
              class: [
                "nav admin-bar-nav",
                {
                  "ml-auto": _vm.isHorizontal,
                  "flex-column": _vm.isVertical,
                },
              ],
            },
            [
              _c(
                "li",
                {
                  staticClass: "nav-item position-relative",
                },
                [
                  _c("div", {
                    attrs: {
                      id: _vm.chatLinkId,
                    },
                  }),
                  _vm._v(" "),
                  _vm.showChatTooltip
                    ? _c(
                        "div",
                        {
                          staticClass: "chat-tooltip px-3 pb-3 shadow",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-right",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "font-weight-bold close-x",
                                  on: {
                                    click: function ($event) {
                                      _vm.showChatTooltip = false
                                    },
                                  },
                                },
                                [_vm._v("X")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "font-weight-bold pb-1 mb-0",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.fcoM[
                                    "rs.authentication.needAssistanceHeading"
                                  ]
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "mb-0",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.fcoM["rs.homeMain.chatTooltipMessage"]
                                )
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.user.masquerade
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link nav-link-masquerade",
                          attrs: {
                            id: "masquerade",
                            href: "javascript:void(0)",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.handleUnmasqueradeClick.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("Icon", {
                            staticClass: "clickable text-md align-text-bottom",
                            attrs: {
                              name: "eye",
                            },
                          }),
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.fcoM["common.masquerade"]) +
                              "\n                "
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isI18nEnabled
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                    },
                    [
                      _c("LanguageSelectButton", {
                        attrs: {
                          "button-class": "header-language-select-button",
                          "label-class": "sr-only",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "nav-item",
                },
                [
                  _c(
                    "FcoLink",
                    {
                      staticClass: "nav-link qaa-username-link",
                      attrs: {
                        to: "/admin/user",
                        href: _vm.fcoUrl(
                          "/user/editpreferences.html?reset=true"
                        ),
                      },
                    },
                    [
                      _c("Icon", {
                        staticClass: "clickable text-md align-text-bottom",
                        attrs: {
                          name: "user",
                        },
                      }),
                      _vm._v(" "),
                      !(
                        (_vm$user = _vm.user) !== null &&
                        _vm$user !== void 0 &&
                        _vm$user.userId
                      )
                        ? _c("VueSkeletonLoader", {
                            attrs: {
                              type: "rect",
                              width: 80,
                              height: 18,
                              rounded: true,
                            },
                          })
                        : [_vm._v(_vm._s(_vm.loggedInUserText))],
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.userCanAccessSupport
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                    },
                    [
                      _c(
                        "FcoLink",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: "/support",
                            href: _vm.fcoUrl("/admin/support.html"),
                          },
                        },
                        [
                          _c("Icon", {
                            staticClass: "clickable text-md align-text-bottom",
                            attrs: {
                              name: "wrench",
                            },
                          }),
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.fcoM["common.support"]) +
                              "\n                "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "nav-item",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link pr-0",
                      attrs: {
                        id: "adminLogOut",
                        href: _vm.isSPA
                          ? "#"
                          : _vm.fcoUrl(
                              `/logout.html?${_vm.csrfObject.parameterName}=${_vm.csrfObject.token}`
                            ),
                      },
                      on: {
                        click: _vm.handleLogoutClick,
                      },
                    },
                    [
                      _c("Icon", {
                        staticClass: "clickable text-md align-text-bottom",
                        attrs: {
                          name: "sign-out",
                        },
                      }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.fcoM["rs.homeMain.logout"]) +
                          "\n                "
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }