<template>
    <FcoLink class="fco-header-brand" :to="homeRoute" :href="fcoUrl(isSMSUser ? '/catalog/browse.html' : '/')">
        <picture>
            <source media="(max-width:767px)" srcset="/images/fco-logo-white-notag.png" />
            <source media="(min-width:768px)" srcset="/images/fco-logo-notag.png" />
            <img class="qaa-header-logo" src="/images/fco-logo-notag.png" :alt="fcoM['rs.homeMain.fcoLogo']" />
        </picture>
    </FcoLink>
</template>

<script>
import { mapGetters } from 'vuex';
import FcoLink from '../FcoLink.vue';

export default {
    name: 'HeaderBrandLink',
    components: { FcoLink },
    computed: {
        ...mapGetters(['isSMSUser', 'isAuthorizedUser']),
        homeRoute() {
            if (!this.isAuthorizedUser) return '/login';
            if (this.isSMSUser) return '/catalog/browse';
            return '/';
        },
    },
};
</script>
