var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "fco-app-content",
      class: _vm.themeClass,
    },
    [
      _vm.isLocalTestProfile && _vm.showProfile
        ? _c(
            "div",
            {
              staticClass:
                "profile-label align-items-center d-flex py-1 px-3 text-bold",
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex-fill text-sm",
                },
                [_vm._v("connected to test resources")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "ml-3 text-lg text-white text-bold text-decoration-none",
                  attrs: {
                    href: "#",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.showProfile = false
                    },
                  },
                },
                [_vm._v("×")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isErrorPage && !_vm.disableHeader && !_vm.isFullScreen
        ? [
            _vm.isAuthorizedUser
              ? [
                  _c("BroadcastMessages"),
                  _vm._v(" "),
                  _c("AdminBar", {
                    staticClass: "d-none d-lg-block d-print-none",
                  }),
                  _vm._v(" "),
                  _c("MainNav"),
                  _vm._v(" "),
                  _vm.showInfoBar ? _c("InfoBar") : _vm._e(),
                ]
              : _c("PublicHeader"),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex-fill",
        },
        [_vm.isSPA ? _c("RouterView") : _vm._t("default")],
        2
      ),
      _vm._v(" "),
      !_vm.isErrorPage && !_vm.disableFooter && !_vm.isFullScreen
        ? _c("FcoFooter")
        : _vm._e(),
      _vm._v(" "),
      _c("FcoToast"),
      _vm._v(" "),
      _c("FcoLoading"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }